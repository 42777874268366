<template>
  <div class="row project-workers">
    <div class="box-tools">
      <div style="display: inline-block;margin-right:15px;" @mouseenter="toggleSearch(true)" @mouseleave="toggleSearch(false)">
        <Select v-model="searchBy" class="table-search" :class="{on: tableSearchOn||searchMode||searchBy}">
          <Option value="">不限</Option>
          <Option :value="col.searchKey?col.searchKey:col.key" v-for="col in columns.filter((c)=>{return c.title && c.searchable})" :key="col.key">{{col.title}}</Option>
        </Select>&nbsp;
        <Input v-model="keyword" ref="tableSearch" placeholder="搜索关键字" @on-focus="toggleSearchMode" @on-blur="toggleSearchMode" class="table-search" :class="{on: tableSearchOn||searchMode||keyword}"></Input>&nbsp;
        <Button type="default" icon="ios-search" @click="search"></Button>
      </div>
      <Button-group>
        <!--<Button type="ghost" icon="md-add" @click="toggleNewForm">新增</Button>-->
        <Button type="default" icon="md-refresh" @click="refreshList">刷新</Button>
      </Button-group>
    </div>
    <div class="col-xs-12">
      <Table stripe :columns="columns" :data="dataViewPage" ref="table" @on-selection-change="selectRowChange"></Table>
      <div style="margin: 10px;overflow: hidden">
        <Button type="primary" size="large" @click="exportData" :disabled="!rowSelected"><Icon type="ios-download-outline"></Icon> 导出原始数据</Button>
        <Button size="large" style="margin-left:5px" @click="exportData('all')"><Icon type="md-list"></Icon> 导出花名册</Button>
        <div style="float: right;">
          <Page :total="data.length" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="pageCurrent" show-sizer></Page>
        </div>
      </div>
      <Modal v-model="modalDetail" v-if="workerSelected">
        <div class="pull-right" v-if="workerSelected.User.HeadImgUrl">
          <img :src="workerSelected.User.HeadImgUrl">
        </div>
        <ul class="list-group list-group-unbordered">
          <li class="list-group-item">
            <b>姓名</b> <span class="">{{ workerSelected.User.Name }}</span>
          </li>
          <li class="list-group-item">
            <b>所属公司</b> <span class="">{{ workerSelected.Unit }}</span>
          </li>
          <li class="list-group-item">
            <b>队伍</b> <span class="">{{ workerSelected.Department }}</span>
          </li>
          <li class="list-group-item">
            <b>工种</b> <span class="">{{ workerSelected.Job }}</span>
          </li>
          <li class="list-group-item">
            <b>进场状态</b> <span><Tag :color="$common.genderColor[workerSelected.IsEnable?'1':'0']">{{ getRegEnableText() }}</Tag></span>
          </li>
          <li class="list-group-item">
            <b>性别</b> <span><Tag :color="$common.genderColor[workerSelected.User.IsMale?'1':'0']">{{ $common.gender[workerSelected.User.IsMale?'1':'0'] }}</Tag></span>
          </li>
          <li class="list-group-item">
            <b>生日</b> <span class="">{{ $moment(workerSelected.User.Birthday).format('YYYY-MM-DD') }}</span>
          </li>
          <li class="list-group-item">
            <b>用户登录名</b> <span class="">{{ workerSelected.User.Username }}</span>
          </li>
          <li class="list-group-item">
            <b>用户权限</b> <span class=""><Tag v-for="(perm,permIdx) in getPermissions()" :key="permIdx">{{perm}}</Tag></span>
          </li>
          <li class="list-group-item">
            <b>账号状态</b> <span><Tag :color="$common.genderColor[workerSelected.User.IsEnable?'1':'0']">{{ getEnableText() }}</Tag></span>
          </li>
          <li class="list-group-item">
            <b>身份证号</b> <span class="">{{ showIdNumber(workerSelected.User.IdNumber) }}</span>
          </li>
          <li class="list-group-item">
            <b>手机号码</b> <span class="">{{ workerSelected.User.MobilePhoneNumber }}</span>
          </li>
          <li class="list-group-item">
            <b>IC卡编码</b> <span class="">{{ workerSelected.User.IcCardId }}</span>
          </li>
          <li class="list-group-item">
            <b>微信openId</b> <span class="">{{ workerSelected.User.Wechat }}</span>
          </li>
          <li class="list-group-item">
            <b>邮箱</b> <span class="">{{ workerSelected.User.Email }}</span>
          </li>
          <li class="list-group-item">
            <b>注册日期</b> <span class="">{{ $moment(workerSelected.User.CreationTime).format('YYYY-MM-DD') }}</span>
          </li>
          <li class="list-group-item">
            <b>标签</b>
            <span class="">
              <Tag v-for="(tag,index) in workerSelected.User.UserTags" :key="index" type="border" color="primary">{{tag.TagName}}</Tag>
              <Button size="small"
                style="font-size:13px; background-color:#238bc5; color:#fff; border-radius: 4px; width:50px; border:none; cursor: pointer;"
                @click="toggleshowTage">添加+</Button>
            </span>
          </li>
        </ul>
      </Modal>
      <Modal v-model="modalDisable" width="360" @on-cancel="cancelDisable">
        <p slot="header" style="color:#f60;text-align:center">
          <Icon type="information-circled"></Icon>
          <span>工人退场</span>
        </p>
        <div style="text-align:center">
          <p>是否确认工人退场？</p>
          <p>退场后工人将无法进出。</p>
        </div>
        <div slot="footer">
          <Button type="error" size="large" long :loading="modal_loading" @click="disableUser">确认</Button>
        </div>
      </Modal>
      <Modal v-model="modalRemove" :mask-closable="false" width="360">
        <p slot="header" style="color:#f60;text-align:center">
          <Icon type="information-circled"></Icon>
          <span>解除施工人员关联</span>
        </p>
        <div style="text-align:center">
          <p>确认解除该施工人员与项目关联么？</p>
        </div>
        <div slot="footer">
          <Button type="error" size="large" long @click="removeWorker">解除</Button>
        </div>
      </Modal>
    </div>
    <workerSignForm :dataSelected="workerSelected" :show="showSignForm" @close="closeSignForm"></workerSignForm>
    <!--<workerAddForm :data="userSelected" :show="showAddForm" @close="closeNewForm"></workerAddForm>-->
    <user-review-records :dataSelected="UserReview" :show="showReview" @close="closeReview"></user-review-records>
    <user-tags-add
      :tagdata="tagdata"
      :show="showTage"
      @close="closeshowTage"
    ></user-tags-add>
  </div>
</template>

<script>
import UserReviewRecords from '../user/UserReviewRecords'
// import workerAddForm from './ProjectWorkerAdd'
import workerSignForm from './ProjectWorkerSigninRecords'
import UserTagsAdd from './../user/UserTagsAdd'


export default {
  name: 'project-workers',
  props: ['project'],
  components: {
    // workerAddForm,
    workerSignForm,
    UserReviewRecords,
    UserTagsAdd,
  },
  data () {
    return {
      projectId: this.$route.params.tid,
      apiItem: {
        apiHost: '',
        apiService: 'ZztPrjBiz',
        apiAction: 'Users/',
        apiQuery: {
        }
      },
      apiData: {
      },
      showReview: false,
      UserReview:{
        User: '',
        UserId: '',
        Project: '',
        ProjectId: '',
      },
      showSignSummaryForm: false,
      showAddForm: false,
      showSignForm: false,
      loading: false,
      userSelected: null,
      workerSelected: null,
      modal_loading: false,
      modalDisable: false,
      modalRemove: false,
      modalDetail: false,
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      keyword: '',
      searchBy: '',
      searchMode: false,
      tableSearchOn: false,
      columns: [
        { type: 'selection', width: 70, align: 'center' },
        {
          title: '姓名',
          searchKey: 'User.Name',
          width: 110,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.Name.localeCompare(b.Name, 'zh-CN')
            } else {
              return b.Name.localeCompare(a.Name, 'zh-CN')
            }
          },
          render: (h, params) => {
            return h('span', {}, params.row.User.Name)
          }
        },
        {
          title: '劳务公司',
          key: 'Unit',
          width: 180,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          }
        },
        {
          title: '标签',
          key: 'User.UserTags',
          width: 300,
          align: 'center',
          ellipsis: true,
          tooltip: true,
          sortable: true,
          searchable: true,
          render: (h, params) =>{
            return h ('span', [
              h (
                'div',
                this.data[params.index].User.UserTags.map(item => {
                  return h('tag',
                  {
                    style: {border: '1px solid #268b97',height: '18px','line-height': '17px'}
                  },
                  item.TagName)
                })
              ),
            ])
          }
        },
        {
          title: '队伍',
          key: 'Department',
          width: 110,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          }
        },
        {
          title: '工种',
          key: 'Job',
          width: 110,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          }
        },
        { title: '身份证号',
          searchKey: 'User.IdNumber',
          width: 120,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.IdNumber.localeCompare(b.IdNumber, 'zh-CN')
            } else {
              return b.IdNumber.localeCompare(a.IdNumber, 'zh-CN')
            }
          },
          render: (h, params) => {
            return h('span', {}, this.showIdNumber(params.row.User.IdNumber))
          }
        },
        {
          title: '手机号',
          searchKey: 'User.MobilePhoneNumber',
          width: 120,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.MobilePhoneNumber.localeCompare(b.MobilePhoneNumber, 'zh-CN')
            } else {
              return b.MobilePhoneNumber.localeCompare(a.MobilePhoneNumber, 'zh-CN')
            }
          },
          render: (h, params) => {
            return h('span', {}, params.row.User.MobilePhoneNumber)
          }
        },
        {
          title: '状态',
          key: 'IsEnable',
          width: 100,
          render: (h, params) => {
            return h('div', [
              h('i-switch', {
                props: {
                  // type: 'ghost',
                  value: params.row.IsEnable,
                  size: 'large'
                },
                on: {
                  'on-change': () => {
                    this.workerSelected = params.row
                    if (params.row.IsEnable) {
                      this.workerSelected.IsEnable = false
                      this.modalDisable = true
                    } else {
                      this.workerSelected.IsEnable = true
                      this.disableUser()
                    }
                  }
                }
              }, [
                h('span', {
                  slot: 'open'
                }, '进场'),
                h('span', {
                  slot: 'close'
                }, '退场')
              ])
            ])
          }
        },
        {
          title: '操作',
          key: 'action',
          // fixed: 'right',
          width: 240,
          render: (h, params) => {
            return h('Button-group', [
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                },
                on: {
                  click: () => {
                    this.workerSelected = params.row
                    this.modalDetail = true
                  }
                }
              }, '详情'),
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.userSelected = null
                    this.userSelected = params.row.User
                    this.modalRemove = true
                    // this.$router.push({ path: '/project/' + this.projectId + '/workers/' + params.row.UserId + '/remove' })
                  }
                }
              }, '解除'),
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                },
                on: {
                  click: () => {
                    // this.workerSelected = null
                    this.workerSelected = params.row
                    this.showSignForm = true
                    // this.$router.push({ path: '/project/' + this.projectId + '/workers/' + params.row.UserId + '/records' })
                  }
                }
              }, '签到记录'),
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                },
                on: {
                  click: () => {
                    this.UserReview = params.row
                    this.UserReview.Project = this.project.Name
                    this.showReview = true
                  }
                }
              }, '回访'),
            ])
          }
        }
      ],
      dataRaw: [],
      data: [],
      dataViewPage: [],
      showTage: false,
      tagdata: {},
    }
  },
  watch: {
    'data': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageCurrent': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageSize': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize
      })
    }
  },
  mounted () {
    this.initDataTable()
  },
  methods: {
    toggleNewForm () {
      this.showAddForm = true
      // this.$router.replace({ path: '/project/' + this.$route.params.id + '/workers/add' })
    },
    closeNewForm () {
      this.showAddForm = false
    },
    toggleSignForm () {
      this.showSignForm = true
      // this.UserReview.Project = this.project.Name
      // this.$router.replace({ path: '/project/' + this.projectId + '/workers/' + this.workerSelected.UserId + '/records' })
    },
    closeSignForm () {
      this.showSignForm = false
      // this.workerSelected = null
    },
    toggleReview () {
      this.showReview = true
    },
    closeReview () {
      this.showReview = false
    },
    toggleshowTage() {
      this.showTage = true
      this.tagdata = this.workerSelected.User
    },
    closeshowTage() {
      this.showTage = false
    },
    refreshList () {
      // refreshList()
      this.initDataTable()
      this.searchBy = ''
      this.keyword = ''
      this.searchMode = false
      this.tableSearchOn = false
    },
    initDataTable () {
      return new Promise((resolve, reject) => {
        this.$Spin.show()
        this.$Api.Project.getProjectWorkers(this.$route.params.tid).then((respBody) => {
          this.dataRaw = respBody
          this.data = respBody
          this.pageSize = 10
          this.pageCurrent = 1
          this.$emit('setWorkerCount', this.dataRaw.length)
          this.$Spin.hide()
          resolve()
        })
        .catch(err => {
          this.$Spin.hide()
          this.$Notice.error({
            title: '获取项目施工人员名单失败!',
            desc: err.message
          })
          reject(err)
        })
      })
    },
    disableUser () {
      let user = this.workerSelected.User
      let userItem = {
        ProjectId: this.$route.params.tid,
        UserId: user.UserId,
        LegalName: user.Name,
        IdNumber: user.IdNumber,
        Tel: user.MobilePhoneNumber,
        Permission: 0,    // 1=基础权限，0=农民工
        Tag: '施工人员',
        IsEnable: (!this.workerSelected.IsEnable).toString()
      }
      this.$Spin.show()
      this.modal_loading = true
      this.$Api.Project.editProjectUser(userItem).then(() => {
        this.modal_loading = false
        this.$Spin.hide()
      })
      .catch(err => {
        this.$Spin.hide()
        this.modal_loading = false
        this.$Notice.error({
          title: '操作失败!',
          desc: err.message
        })
        this.modalDisable = false
        this.cancelDisable()
      })
    },
    cancelDetail () {
      this.modalDetail = false
      this.workerSelected = null
    },
    cancelDisable () {
      this.workerSelected.IsEnable = true
    },
    removeWorker () {
      this.userItem = {
        UserId: this.userSelected.Id,
        IdNumber: this.userSelected.IdNumber,
        Tel: this.userSelected.MobilePhoneNumber,
        Permission: 0,    // 农民工
        ProjectId: this.$route.params.tid
      }
      this.$Spin.show()
      this.submitEnabled = false
      this.$Api.Project.removeProjectUser(this.userItem).then(() => {
        this.refreshList()
        this.$Spin.hide()
        this.submitEnabled = true
        this.userSelected = null
        this.modalRemove = false
      })
      .catch(err => {
        this.$Spin.hide()
        this.submitEnabled = true
        this.$Notice.error({
          title: '操作失败!',
          desc: err.message
        })
      })
    },
    selectRowChange (selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage (page) {
      this.pageCurrent = page
    },
    changePageSize (pageSize) {
      this.pageSize = pageSize
    },
    exportData (mode) {
      let exportRange = this.rowSelectedList
      if (mode === 'all') {
        exportRange = this.dataRaw
      }
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: [
          { key: '姓名' },
          { key: '劳务公司' },
          { key: '队伍' },
          { key: '工种' },
          { key: '身份证号' },
          { key: '手机号' },
          { key: '状态' }
        ],
        data: exportRange.map((item) => {
          return {
            '姓名': item.User.Name,
            '劳务公司': item.Unit,
            '队伍': item.Department,
            '工种': item.Job,
            '身份证号': '="' + item.User.IdNumber + '"',
            '手机号': '="' + item.User.MobilePhoneNumber + '"',
            '状态': item.IsEnable ? '已进场' : '已退场'
          }
        })
      })
    },
    toggleSearchMode () {
      this.searchMode = !this.searchMode
    },
    toggleSearch (val) {
      this.tableSearchOn = val
    },
    search () {
      let cols = this.columns.filter((col) => {
        return col.searchable
      })
      this.data = this.dataRaw.filter((item) => {
        if (this.searchBy) {
          let content = this.getSearchQueryContent(item, this.searchBy)
          return content.toString().indexOf(this.keyword) >= 0
        } else {
          let found = false
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i]
            let content = this.getSearchQueryContent(item, col.searchKey ? col.searchKey : col.key)
            if (content && (content.toString().indexOf(this.keyword) >= 0)) {
              found = true
            }
          }
          return found
        }
      })
      this.pageCurrent = 0
      this.pageCurrent = 1
    },
    getSearchQueryContent (item, key) {
      if (!key) {
        return ''
      }
      let qArr = []
      let qArrTemp = key.split('.')
      qArrTemp.forEach((q) => {
        if (q.indexOf(']')) {
          let subArr = q.replace(/]/g, '').split('[')
          subArr.forEach((sub) => {
            qArr.push(sub)
          })
        } else {
          qArr.push(q)
        }
      })
      let content = item
      qArr.forEach((query) => {
        if (content[query]) {
          content = content[query]
        } else {
          content = ''
        }
      })
      return content
    },
    getUser (userId) {
      this.userSelected = null
      this.userSelected = this.datatableData.filter(function (item) {
        return item.UserId === userId
      })[0].User
    },
    getWorker (userId) {
      this.workerSelected = null
      this.workerSelected = this.datatableData.filter(function (item) {
        return item.UserId === userId
      })[0]
    },
    getUserPermissionsStr (row) {
      let resultStr = '项目查看'
      if (row.CanEdit) {
        resultStr += '、项目修改'
      }
      if (row.CanAssign) {
        resultStr += '、关联人员'
      }
      if (row.CanUploadReport) {
        resultStr += '、上报月报'
      }
      if (row.CanViewReport) {
        resultStr += '、查看月报'
      }
      return resultStr
    },
    hideAddForm () {
      this.showAddForm = false
    },
    showIdNumber (id) {
      let idnumber = ''
      if (id) {
        if (id.length === 18) {
          idnumber = id.substr(0, 10) + '****'
        } else if (id.length === 15) {
          idnumber = id.substr(0, 8) + '****'
        }
      }
      return idnumber
    },
    getPermissions () {
      let perms = []
      if (this.workerSelected.User.Permissions) {
        this.workerSelected.User.Permissions.forEach((p) => {
          perms.push(this.$common.permissions[p.toString()])
        })
      }
      return perms
    },
    getEnableText () {
      var text = this.workerSelected.User.IsEnable ? '已启用' : '已禁用'
      return text
    },
    getRegEnableText () {
      var text = this.workerSelected.IsEnable ? '已进场' : '已退场'
      return text
    }
  }
}
</script>
