<template>
  <div class="alarm-detail">
    <Modal
      v-model="visible"
      title="预警信息详情"
      width="1024"
      :styles="{top: '20px'}"
      :mask-closable="false"
      @on-cancel="returnPath">
      <div class="modal-body" v-if="alarm.Id>0">
        <Card class="brief-box" style="padding:0;">
          <Row>
            <Col span="12">
              <ul class="list-group list-group-unbordered">
                <li class="list-group-item">
                  <b><Icon type="ios-pie"></Icon> 类型</b>
                  <span class="pull-right font-grey">{{ alarm.Type }}</span>
                </li>
                <li class="list-group-item">
                  <b><Icon type="stats-bars"></Icon> 等级</b>
                  <span class="pull-right font-grey">{{ alarm.Level }}</span>
                </li>
                <li class="list-group-item">
                  <b><Icon type="ios-paperplane"></Icon> 状态</b>
                  <span class="pull-right font-grey">{{ activeStatus(alarm.IsActive) }}</span>
                </li>
              </ul>
            </Col>
            <Col span="12">
              <ul class="list-group list-group-unbordered">
                <li class="list-group-item">
                  <b><Icon type="ios-person"></Icon> 发起人</b>
                  <span class="pull-right font-grey">{{ alarm.AnnouncerId }}</span>
                </li>
                <li class="list-group-item">
                  <b><Icon type="ios-calendar"></Icon> 上报时间</b> <span class="pull-right font-grey">{{ $moment(alarm.Time).format('YYYY-MM-DD HH:mm:ss') }}</span>
                </li>
              </ul>
            </Col>
          </Row>
        </Card>
        <Card :bordered="false" dis-hover v-if="messages[0]">
          <p slot="title">
            预警内容
            <small class="pull-right"><Icon type="images"></Icon> {{ messages[0].AttachmentImgs.length }}张</small>
          </p>
          <div class="box-body">
            <div class="pre">{{ messages[0].Content }}</div>
            <hr v-if="messages[0].AttachmentImgs.length">
            <ul class="report-files" v-if="messages[0].AttachmentImgs.length">
              <li class="report-file preview-img"
                :style="{ backgroundImage: 'url(' + img.ImgUrl + ')' }"
                @click="openGallery(idx)"
                v-for="img, idx in messages[0].AttachmentImgs"
                :key="idx">
              </li>
            </ul>
          </div>
          <LightBox ref="Lightbox" id="alarmLightBox"
            :media="messages[0].ImgSrcArr"
            :showLightBox="showLightBox"
            :showCaption="showCaption"
            :showThumbs="showThumbs">
          </LightBox>
        </Card>
        <div class="padding-top-20"></div>
        <Card :bordered="false" dis-hover v-if="messages.length>1">
          <p slot="title">
            追加
          </p>
          <div class="box-body">
            <div class="complaint-reply" v-for="(rep, index) in messages.filter((msg,idx)=>{return idx>0})" :key="index">
              <div class="complaint-content" :class="{author: !rep.IsAuthor}">{{rep.Content}}
                <ul class="report-files">
                  <li class="report-file preview-img"
                    :style="{ backgroundImage: 'url(' + img.ImgUrl + ')' }"
                    @click="openReplyGallery(idx, index)"
                    v-for="img, idx in rep.AttachmentImgs"
                    :key="idx">
                  </li>
                </ul>{{showReplyDateTime(rep.Time)}}
              </div>
              <span class="complaint-time"><div class="pre"></div></span>
              <LightBox :ref="`MsgLightbox-${index}`" class="light-box-alarm-msg"
                :media="rep.ImgSrcArr"
                :showLightBox="showReplyLightBox"
                :showCaption="showCaption"
                :showThumbs="showThumbs">
              </LightBox>
            </div>
          </div>
        </Card>
        <div class="padding-top-20"></div>
      </div>
      <div slot="footer" v-if="alarm.Id>0">
        <Row>
          <Col span="4" class="text-left">
            <Button class="pull-left" @click="returnPath">取消</Button>
            <Button type="success" @click="closeAlarm" v-if="alarm.IsActive">关闭预警</Button>
          </Col>
          <Col span="20">
            <div style="padding: 0 10px;display: flex; flex-direction: row">
              <Input type="textarea" :autosize="true" v-model="supplyForm.Message.Content" placeholder="请输入追加内容..."></Input>
              <Upload
                ref="AttachUpload"
                multiple
                :data="OSSParams"
                :before-upload="handleBeforeUpload"
                :on-success="handleUploadSuccess"
                :format="['jpg','jpeg','png']"
                :max-size="2048"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :action="uploadParams.host">
                <Tooltip placement="top">
                  <Button type="default" @click="getOSSParams()" style="margin-left: 5px;"><Icon type="ios-cloud-upload-outline"></Icon>上传文件</Button>
                  <div slot="content">
                    <p>上传图片要求：</p>
                    <p>
                      <ol>
                        <li>1.图片信息内容必须<i><b><u>清晰易于辨认</u></b></i></li>
                        <li>2.图片文件格式：<i><b><u>jpg,jpeg,png</u></b></i></li>
                        <li>3.允许上传图片数量：<i><b><u>5张</u></b></i></li>
                        <li>4.单张图片大小：<i><b><u>＜2mb</u></b></i></li>
                      </ol>
                    </p>
                  </div>
                </Tooltip>
              </Upload>
              <div class="">
                <Button type="primary" style="margin-left: 5px;" @click="handleSupply">追加</Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
    <Modal v-model="modalClose" width="360">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="information-circled"></Icon>
        <span>确认关闭投诉</span>
      </p>
      <div style="text-align:center">
        <p>是否关闭该投诉？</p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long :loading="modal_loading" @click="submitClose">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import LightBox from 'vue-image-lightbox'

require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  components: {
    LightBox
  },
  props: ['dataSelected', 'show'],
  data () {
    return {
      visible: false,
      projectId: this.$route.params.tid,
      alarm: {
        Id: 0,
        Type: 0,
        Level: 0,
        AnnouncerId: 0,
        IsActive: true,
        ProjectName: '',
        ProjectId: 0,
        Time: ''
      },
      messages: [
      ],
      imgArr: [],
      showLightBox: false,
      showReplyLightBox: false,
      showThumbs: true,
      showCaption: true,
      modalClose: false,
      modal_loading: false,
      supplyForm: {
        Id: 0,
        ProjectId: null,
        ProjectName: '',
        Message: {
          Attachments: '',
          Content: ''
        },
        AttachmentImgs: []
      },
      uploadParams: {
        accessid: '',
        host: '',
        policy: '',
        signature: '',
        callbackbody: '',
        key: '',
        expire: 0
      },
      OSSParams: {
        name: '',
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',   // 让服务端返回200,不然，默认会返回204
        callback: '',
        signature: ''
      }
    }
  },
  mounted () {
    if (this.dataSelected) {
      this.alarm.Id = this.dataSelected.Id
      this.supplyForm.Id = this.dataSelected.Id
      this.getAlarm()
    }
  },
  watch: {
    'show': function (newVal) {
      this.visible = newVal
    },
    'dataSelected': function (newVal) {
      if (newVal) {
        this.alarm.Id = newVal.Id
        this.supplyForm.Id = newVal.Id
        this.getAlarm()
      }
    }
  },
  methods: {
    getAlarm () {
      this.imgArr = []
      this.messages = []
      this.$Spin.show()
      this.$Api.Project.getAlarmDetail(this.alarm.Id).then((respBody) => {
        this.alarm = {
          Id: respBody.Alarm.Id,
          Type: respBody.Alarm.Type,
          Level: respBody.Alarm.Level,
          AnnouncerId: respBody.Alarm.AnnouncerId,
          IsActive: respBody.Alarm.IsActive,
          ProjectName: respBody.Alarm.ProjectName,
          ProjectId: respBody.Alarm.ProjectId,
          Time: respBody.Alarm.Time
        }
        respBody.Messages.forEach((msg) => {
          let msgItem = {
            Attachments: msg.Attachments,
            AttachmentImgs: [],
            ImgSrcArr: [],
            Content: msg.Content,
            AuthorId: msg.AuthorId,
            Time: msg.Time
          }
          this.procAttachments(msgItem)
          this.messages.push(msgItem)
        })
        if (this.messages.length > 1) {
          this.messages.sort(function (a, b) {
            return new Date(a.Time) - new Date(b.Time)
          })
        }
        this.$Spin.hide()
      })
      .catch(err => {
        this.$Spin.hide()
        this.$Notice.error({
          title: '获取失败!',
          desc: err.message
        })
      })
    },
    procAttachments (item) {
      let imgArr = []
      let imgSrcArr = []
      let attachments = item.Attachments
      if (attachments) {
        attachments.split(',').forEach((item) => {
          let imgObj = {
            FileName: item,
            ImgUrl: ''
          }
          imgArr.push(imgObj)
          this.getImgUrl(imgObj, () => {
            let imgSrcItem = {
              thumb: imgObj.ImgUrl,
              src: imgObj.ImgUrl,
              caption: '预警附件'
            }
            imgSrcArr.push(imgSrcItem)
          })
        })
      }
      item.AttachmentImgs = imgArr
      item.ImgSrcArr = imgSrcArr
    },
    getImgUrl (img, callback) {
      if (!img.FileName) {
        img.ImgUrl = require('../../assets/image.png')
      } else {
        this.$Spin.show()
        this.$Api.Aliyun.getFileUrl(img.FileName).then((respBody) => {
          this.$Spin.hide()
          this.$nextTick(function () {
            img.ImgUrl = respBody.replace('-internal', '')
          })
          if (callback) {
            this.$nextTick(function () {
              callback()
            })
          }
        })
        .catch(err => {
          this.$Spin.hide()
          this.$Notice.error({
            title: '获取附件失败!',
            desc: err.message
          })
          img.ImgUrl = require('../../assets/image.png')
        })
      }
    },
    openGallery (idx) {
      this.showLightBox = true
      this.$nextTick(() => {
        this.$refs.Lightbox.showImage(idx)
      })
    },
    openReplyGallery (idx, index) {
      this.showReplyLightBox = true
      this.$nextTick(() => {
        this.$refs[`MsgLightbox-${index}`][0].showImage(idx)
      })
    },
    returnPath () {
      this.$emit('close')
      this.$Modal.remove()
      // this.$router.push({ path: '/project/' + this.$route.params.id + '/complaints' })
    },
    activeStatus (data) {
      switch (data) {
        case true:
          return '未处理'
        case false:
          return '已处理'
        default:
          return '未知'
      }
    },
    getTime (time) {
      return time.replace('T', ' ')
    },
    showReplyDateTime (time) {
      time = time.replace('T', ' ')
      return time
    },
    closeAlarm () {
      this.modalClose = true
    },
    submitClose () {
      this.modal_loading = true
      this.$Spin.show()
      this.$Api.Project.closeAlarm(this.alarm.Id).then(() => {
        this.modal_loading = false
        this.$Spin.hide()
        this.modalClose = false
      })
      .catch(err => {
        this.$Spin.hide()
        this.modal_loading = false
        this.$Notice.error({
          title: '获取失败!',
          desc: err.message
        })
      })
    },
    handleSupply () {
      if (!this.supplyForm.Message.Content) {
        this.$Message.error('追加内容不能为空!')
      } else {
        this.modal_loading = true
        this.supplyForm.Message.Attachments = this.supplyForm.AttachmentImgs.join(',')
        this.$Spin.show()
        this.$Api.Project.replyAlarm(this.supplyForm).then(() => {
          this.$Spin.hide()
          this.getAlarm()
          this.supplyForm = {
            Id: this.dataSelected.Id,
            ProjectId: this.projectId,
            ProjectName: '',
            Message: {
              Attachments: '',
              Content: ''
            },
            AttachmentImgs: []
          }
          this.$refs.AttachUpload.fileList = []
          this.modalApprove = false
          this.modal_loading = false
        })
        .catch(err => {
          this.$Spin.hide()
          this.modal_loading = false
          this.$Notice.error({
            title: '获取失败!',
            desc: err.message
          })
        })
      }
    },
    getOSSParams () {
      this.$Api.Aliyun.getUploadParams().then((respBody) => {
        this.uploadParams = {
          accessid: respBody.accessid,
          host: respBody.host,
          policy: respBody.policy,
          signature: respBody.signature,
          callbackbody: respBody.callbackbody,
          key: respBody.dir,
          expire: respBody.expire
        }
      })
      .catch(err => {
        this.$Notice.error({
          title: '获取上传参数失败!',
          desc: err.message
        })
      })
      this.apiItem = {
        apiHost: '',
        apiService: 'Aliyun',
        apiAction: 'RequestCommonUpload/bidguamgt-demo/zzt/',
        apiQuery: {
        }
      }
    },
    handleBeforeUpload (file) {
      let filename = this.uploadParams.key + randomString(10) + getSuffix(file.name)
      this.OSSParams.name = file.name
      this.OSSParams.key = filename
      this.OSSParams.policy = this.uploadParams.policy
      this.OSSParams.OSSAccessKeyId = this.uploadParams.accessid
      this.OSSParams.success_action_status = '200'   // 让服务端返回200,不然，默认会返回204
      this.OSSParams.signature = this.uploadParams.signature
    },
    handleUploadSuccess (response, file, fileList) {
      if (fileList.length > 6) {
        this.$refs.AttachUpload.fileList.splice(0, 1)
      }
      let filename = 'oss:' + this.OSSParams.key
      this.supplyForm.AttachmentImgs.push(filename)
    },
    handleFormatError () {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '上传文件格式不正确，请上传 jpg 或 png 格式的图片。'
      })
    },
    handleMaxSize () {
      this.$Notice.warning({
        title: '超出文件大小限制',
        desc: '文件太大，不能超过 2M。'
      })
    }
  },
  beforeDestroy () {
  }
}
function randomString (len) {
  len = len || 32
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

function getSuffix (filename) {
  let pos = filename.lastIndexOf('.')
  let suffix = ''
  if (pos !== -1) {
    suffix = filename.substring(pos)
  }
  return suffix
}
</script>

<style scoped>
.modal-body {
  background: #ecf0f5;
  margin: -16px;
  padding: 16px;
}
.brief-box {
  padding: 15px;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 20px;
}
.report-files {
  margin: 0;
  padding: 0;
  list-style: none;
}
.report-files > .report-file {
  display: inline-block;
  width: 120px;
  height: 120px;
  border: 1px solid #eee;
  background-size: cover;
  background-position: center;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
}
.report-files > .report-file:hover {
  border: 1px solid powderblue;
}
.pre {
  word-break: normal;
  white-space: pre-wrap;
  font-size: 14px;
}
.complaint-content {
  color: #999;
  white-space: pre-wrap;
  word-break: break-all;
  background: #fff;
  line-height: 1.4em;
  font-size: 14px;
}
.complaint-reply {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items:flex-end;
  margin-bottom: 10px;
}
.complaint-reply.author {
  flex-direction: row-reverse;
}
.complaint-reply .complaint-content {
  color: #555;
  background: #f2f2f2;
  border-radius: 8px;
  line-height: 1.4em;
  padding: 10px;
  display: inline-block;
  flex: 1;
}
.complaint-reply .complaint-content.author {
  color: #666;
  background: rgba(213,232,222,1);
}
.complaint-reply .complaint-time {
  color: #aaa;
  font-size: 11px;
  width: 30%;
  align-content:flex-end;
  /*display: flex;*/
  align-items: baseline;
  height: 100%;
  padding: 5px;
  text-align: right;
}
.complaint-reply .complaint-time.author {
  text-align: left;
}
hr {
  border:none;
  border-top: 1px solid #f2f2f2;
  margin: 10px 0;
}
</style>

