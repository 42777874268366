<template>
  <div class="project-detail">
    <pageHeaderView></pageHeaderView>
    <section class="content">
      <Card class="brief-card">
        <Row type="flex" justify="start">
          <Col :span="ejected?24:15">
            <div class="prj-hover-icons">
              <span class="edit-prj-icon font-grey" title="修改项目信息" @click="$router.push({path:'/projects/edit/'+$route.params.tid})">
                <Icon type="md-create"></Icon>
              </span>
              <span class="eject-prj-icon font-grey" :class="{ejected:ejected}" title="显示/收起项目信息" @click="toggleEject">
                <Icon :type="ejected?'md-eye':'md-eye-off'"></Icon>
              </span>
            </div>
            <Row type="flex" justify="center" :style="{padding:ejected?'0px':'20px'}">
              <Col span="6" style="text-align: center;">
                <img class="profile-project-img" src="../../assets/libs/img/project-128x128.jpg" :alt="project.Name">
              </Col>
              <Col span="18" style="display: flex;flex-direction: column;justify-content: center;padding: 10px;">
                <h4 class="profile-project-name">
                  {{ project.Name }}
                  <Tag type="border" color="blue">{{ project.Tender }}标段</Tag>
                  <Tag v-if="project&&project.Status>=0" :color="$common.projectStatusColor[project.Status.toString()]">{{$common.projectStatus[project.Status.toString()]}}</Tag>
                  <Poptip v-model="showQRCode" placement="bottom" width="240">
                    <Button icon="md-barcode"></Button>
                    <div class="api" slot="content">
                      <img :src="miniprogramQRCode" width="100%" />
                      <div class="padding-top-20"></div>
                      <a :href="miniprogramQRCode" :download="project.Name">下载二维码</a>
                    </div>
                  </Poptip>
                  
                </h4>
                <div class="divider-horizion green"></div>
                <span class="profile-project-address font-grey"><Icon type="location"></Icon> {{ getArea(project) }}&nbsp;{{ project.Address }}</span>
              </Col>
            </Row>
            <div class="divider-horizion" v-show="!ejected" style="margin-left: -16px;"></div>
            <Row type="flex" justify="center" :class="{ejected: ejected}">
              <Col span="12" style="padding:0 10px;">
                <div class="block-header">
                  <span class="block-header-text">基础信息</span>
                </div>
                <ul class="list-group list-group-unbordered">
                  <!-- <li class="list-group-item" title="项目登记编号">
                    <b><i class="fa fa-user margin-r-5"></i> 项目登记编号</b> <span class="pull-right font-grey">{{ project.RegistrationNumber }}</span>
                  </li> -->
                  <li class="list-group-item" title="施工许可证号">
                    <b><i class="fa fa-user margin-r-5"></i> 施工许可证号</b> <span class="pull-right font-grey">{{ project.ConstructionPermitNumber }}</span>
                  </li>
                  <li class="list-group-item">
                    <b><i class="fa fa-tag margin-r-5"></i> 项目类型</b>
                    <span class="pull-right font-grey">{{ $common.projectType[project.Type.toString()] }}</span>
                  </li>
                  <li class="list-group-item">
                    <b><i class="fa fa-calendar margin-r-5"></i> 计划</b>
                    <span class="pull-right font-grey">
                      {{ $moment(project.PlannedStartDate).format('YYYY-MM-DD') }}-{{ $moment(project.PlannedCompletionDate).format('YYYY-MM-DD') }}
                    </span>
                  </li>
                  <li class="list-group-item" title="项目联系人">
                    <b><i class="fa fa-user margin-r-5"></i> 联系人</b> <span class="pull-right font-grey">{{ project.PrjContactName }}({{ project.PrjContactNumber }})</span>
                  </li>
                  <li class="list-group-item">
                    <b><i class="fa fa-cogs margin-r-5"></i> 管理员</b> <a class="pull-right font-grey">{{ project.PrjContactName }}</a>
                  </li>
                </ul>
                <div class="padding-top-20" />
                <Button type="default" long @click="modalProjectDetail=true">详情</Button>
              </Col>
              <div class="divider-vertical" style="margin-top:-8px;"></div>
              <Col span="12" style="padding:0 10px;margin-left: -1px;">
                <projectCompaniesBoxView :data="project"></projectCompaniesBoxView>
              </Col>
            </Row>
          </Col>
          <div class="divider-vertical" v-show="!ejected"></div>
          <Col span="9" style="margin-left: -1px" :class="{ejected: ejected}">
            <div class="block-header">
              <span class="block-header-text">综合信息</span>
            </div>
            <Row type="flex" justify="center" :gutter="32">
              <Col span="12" style="display: flex;justify-content: center;margin-bottom:16px;"  v-if="checkRolePermitted(['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营','监管人'])">
                <i-circle :percent="alarmCount>0?100:0" stroke-color="#ff5500">
                  <span class="demo-i-circle-inner" style="cursor: pointer;font-size:24px" @click="curTab='alarms'">
                    {{alarmCount}}<br>
                    <span style="cursor: pointer;font-size: 14px;color: #999;" @click="curTab='alarms'">
                      预警
                    </span>
                  </span>
                </i-circle>
              </Col>
              <Col span="12" style="display: flex;justify-content: center;margin-bottom:16px;" v-if="checkRolePermitted(['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营'])">
                <i-circle :percent="complaintCount>0?100:0" stroke-color="#ff9900">
                  <span class="demo-i-circle-inner" style="cursor: pointer;font-size:24px" @click="curTab='complaints'">
                    {{complaintCount}}<br>
                    <span style="cursor: pointer;font-size: 14px;color: #999;" @click="curTab='complaints'">
                      投诉
                    </span>
                  </span>
                </i-circle>
              </Col>
              <!-- <Col span="12" style="display: flex;justify-content: center;margin-left: -1px;">
                <i-circle :percent="100" stroke-color="#5cb85c">
                  <span class="demo-i-circle-inner" style="cursor: pointer;font-size:24px" @click="curTab='devices'">
                    {{deviceCount}}<br>
                    <span style="cursor: pointer;font-size: 14px;color: #999;" @click="curTab='devices'">
                      台设备
                    </span>
                  </span>
                </i-circle>
              </Col> -->
              <Col span="12" style="display: flex;justify-content: center;margin-bottom:16px;">
                <i-circle :percent="100" stroke-color="#5cb85c">
                  <span class="demo-i-circle-inner" style="cursor: pointer;font-size:24px" @click="curTab='workers'">
                    {{workerCount}}<br>
                    <span style="cursor: pointer;font-size: 14px;color: #999;" @click="curTab='workers'">
                      名施工人员
                    </span>
                  </span>
                </i-circle>
              </Col>
              <Col span="12" style="display: flex;justify-content: center;margin-bottom:16px;">
                <i-circle :percent="100">
                  <span class="demo-i-circle-inner" style="cursor: pointer;font-size:24px" @click="curTab='persons'">
                    {{personCount}}<br>
                    <span style="cursor: pointer;font-size: 14px;color: #999;" @click="curTab='persons'">
                      名管理人员
                    </span>
                  </span>
                </i-circle>
              </Col>
              <!-- <Col span="12" style="display: flex;justify-content: center;margin-left: -1px;">
                <i-circle :percent="100">
                  <span class="demo-i-circle-inner" style="cursor: pointer;font-size:24px" @click="curTab='reports'">
                    {{reportCount}}<br>
                    <span style="cursor: pointer;font-size: 14px;color: #999;" @click="curTab='reports'">
                      份报表
                    </span>
                  </span>
                </i-circle>
              </Col> -->
            </Row>
            <div class="padding-top-20" style="padding-left:16px">
              <Button type="primary" ghost long icon="ios-map" size="large" @click="viewProjectInMap" :disabled="!project.Longitude||!project.Latitude">查看项目位置</Button>
            </div>
            <div class="padding-top-10" style="padding-left:16px" v-if="checkRolePermitted(['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营'])">
              <Button type="primary" ghost long icon="ios-pin" size="large" @click="setProjectGeofenceMap">设置地理围栏</Button>
            </div>
            <!-- <div class="padding-top-20" style="padding-left:16px" v-if="siteMonitorOnline">
              <Button @click="openSiteMonitor" type="success" long icon="ios-pulse" size="large">实时监控</Button>
            </div>
            <div class="padding-top-20" style="padding-left:16px" v-else>
              <Button @click="openSiteMonitor" type="ghost" long icon="ios-pulse" size="large" disabled>实时监控（离线）</Button>
            </div> -->
          </Col>
        </Row>
        <!--<projectBriefBoxView :data="project"></projectBriefBoxView>-->
        <Modal v-model="modalProjectDetail" title="项目详情" @on-cancel="modalProjectDetail=false">
          <ul class="list-group list-group-unbordered project-crawler">
            <li class="list-group-item">
              <b>项目名称</b> <span class="font-grey">{{ project.Name }}</span>
              <Tag type="border" color="blue">{{ project.Tender }}标段</Tag>
            </li>
            <li class="list-group-item" title="施工许可证号">
              <b>施工许可证号</b> <span class="font-grey">{{ project.ConstructionPermitNumber }}</span>
            </li>
            <li class="list-group-item" title="工资专户">
              <b>工资专户</b> <span class="font-grey">{{ project.BankAccountNumber }}({{ project.BankName }})</span>
            </li>
            <li class="list-group-item">
              <b>项目类型</b>
              <span class="font-grey">{{ $common.projectType[project.Type.toString()] }}</span>
            </li>
            <li class="list-group-item" title="项目联系人">
              <b>联系人</b> <span class="font-grey">{{ project.PrjContactName }}({{ project.PrjContactNumber }})</span>
            </li>
            <li class="list-group-item">
              <b>管理员</b> <a class="font-grey">{{ project.PrjContactName }}</a>
            </li>
            <li class="list-group-item">
              <b>项目地址</b> <span class="font-grey">{{ project.Address }}</span>
            </li>
            <li class="list-group-item">
              <b>项目所在地</b> <span class="font-grey">{{ getArea(project) }}</span>
            </li>
            <li class="list-group-item">
              <b>建设用地规划许可证</b> <span class="font-grey">{{ projectCrawler.landPermitLicense }}</span>
            </li>
            <li class="list-group-item">
              <b>建设工程规划许可证</b> <span class="font-grey">{{ projectCrawler.planPermitLicense }}</span>
            </li>
            <li class="list-group-item">
              <b>立项文号</b> <span class="font-grey">{{ projectCrawler.projectApproveLicense }}</span>
            </li>
            <li class="list-group-item">
              <b>立项级别</b> <span class="font-grey">{{ projectCrawler.projectApproveGrade }}</span>
            </li>
            <li class="list-group-item">
              <b>总投资（万元）</b> <span class="font-grey">{{ projectCrawler.invest }}</span>
            </li>
            <li class="list-group-item">
              <b>建设规模（平方米/米）</b> <span class="font-grey">{{ projectCrawler.scale }}</span>
            </li>
            <li class="list-group-item">
              <b>建设性质</b> <span class="">{{ projectCrawler.type }}</span>
            </li>
            <li class="list-group-item">
              <b>建设用途</b> <span class="font-grey">{{ projectCrawler.usage }}</span>
            </li>
            <li class="list-group-item">
              <b>计划开竣工</b> <span class="font-grey">{{ projectCrawler.plannedStartDate }} - {{ projectCrawler.plannedEndDate }}</span>
            </li>
            <li class="list-group-item">
              <b>创建时间</b> <span class="font-grey">{{ $moment(project.CreatedTime).format('YYYY-MM-DD') }}</span>
            </li>
          </ul>
        </Modal>
        <Modal v-model="modalSiteMonitor" title="实时监控" :styles="{top: '10px'}" width="1280" @on-cancel="siteMonitorClose">
          <iframe width="100%" height="600" frameborder="0" id="siteMonitor" :src="modalSiteMonitor?'/monitor.html#/'+projectId:''"></iframe>
        </Modal>
      </Card>
      <div class="padding-top-20"></div>
      <Card>
        <Tabs v-model="curTab">
          <!--<TabPane label="综合信息" name="dashboard">
            <keep-alive>
              <router-view v-if="curTab==='dashboard'" :data="project"></router-view>
            </keep-alive>
          </TabPane>-->
          <!-- <TabPane label="设备" name="devices">
            <keep-alive>
              <router-view v-if="curTab==='devices'" :dataSelected="project" @setDeviceCount="setDeviceCount"></router-view>
            </keep-alive>
          </TabPane> -->
          <TabPane label="管理人员" name="persons">
            <projectPersons v-if="curTab==='persons'" :project="project" @setPersonCount="setPersonCount" />
          </TabPane>
          <!-- <TabPane label="月报" name="reports">
            <keep-alive>
              <router-view v-if="curTab==='reports'" :project="project" @setReportCount="setReportCount"></router-view>
            </keep-alive>
          </TabPane> -->
          <TabPane label="施工人员" name="workers">
            <projectWorkers v-if="curTab==='workers'" :project="project" @setWorkerCount="setWorkerCount" />
          </TabPane>
          <TabPane label="考勤记录" name="attendances">
            <projectAttendances v-if="curTab==='attendances'" :project="project" />
          </TabPane>
          <!-- <TabPane label="工资表" name="wages">
            <keep-alive>
              <router-view v-if="curTab==='wages'" :project="project"></router-view>
            </keep-alive>
          </TabPane> -->
          <TabPane label="欠薪投诉" name="complaints" v-if="checkRolePermitted(['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营'])">
            <projectComplaints v-if="curTab==='complaints'" :project="project" @setComplaintCount="setComplaintCount" />
          </TabPane>
          <TabPane label="风险预警" name="alarms" v-if="checkRolePermitted(['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营','监管人'])">
            <projectAlarms v-if="curTab==='alarms'" :project="project" @setAlarmCount="setAlarmCount" />
          </TabPane>
        </Tabs>
      </Card>
    </section>
    <mapCoordPicker :show="showMapPicker" :mode="mapPickerMode" :picked="mapPickerPickedPoints" @close="closeMapPicker" @select="mapPicked" @select-multi="mapMultiPicked" />
  </div>
</template>

<script>
import pageHeaderView from '../public/PageHeader'
// import projectBriefBoxView from './ProjectBriefBox'
import projectCompaniesBoxView from './ProjectCompaniesBox'
// import projectDashboardView from './projectDashboard'
import projectWorkers from './ProjectWorkers.vue'
import projectPersons from './ProjectPersons.vue'
import projectAttendances from './ProjectAttendances.vue'
import projectComplaints from './ProjectComplaints.vue'
import projectAlarms from './ProjectAlarms.vue'
import cities from './../../assets/cities'
import leftPad from 'leftpad'
import mapCoordPicker from '../public/MapCoordPicker.vue'
import QRCode from 'qrcode'

export default {
  name: 'project-detail',
  components: {
    pageHeaderView,
    // projectBriefBoxView,
    projectCompaniesBoxView,
    projectWorkers,
    projectPersons,
    projectAttendances,
    projectComplaints,
    projectAlarms,
    mapCoordPicker
    // projectDashboardView
  },
  watch: {
    'curTab': function (newVal) {
      console.log(newVal)
      // this.$router.push({ path: '/projects/view/' + this.projectId + '/' + newVal })
    }
  },
  data () {
    return {
      projectId: this.$route.params.tid,
      project: {
        Name: '',
        Type: -1,
        Address: '',
        Tender: '',
        PlannedStartDate: '',
        PlannedCompletionDate: '',
        GuaranteeType: -1,
        Companies: [],
        GuarantorId: 0,
        GuarantorName: '',
        AgentId: 0,
        AgentName: '',
        PrjContactName: '',
        PrjContactNumber: '',
        MngContactName: '',
        MngContactNumber: '',
        BankAccountNumber: '',
        BankName: '',
        CreatedTime: ''
      },
      projectCrawler: {
        projectName: '',                         // 项目名称
        owner: '',                                     // 建设单位
        area: '',                                       // 项目所在地
        landPermitLicense: '',             // 建设用地规划许可证
        planPermitLicense: '',             // 建设工程规划许可证
        projectApproveLicense: '',     // 立项文号
        projectApproveGrade: '',         // 立项级别
        invest: '',                                   // 总投资
        scale: '',                                     // 建设规模
        type: '',                                       // 建设性质（新建，改扩建）
        usage: '',                                     // 建设用途
        plannedStartDate: '',               // 计划开工
        plannedEndDate: ''                   // 计划竣工
      },
      miniprogramQRCode: null,
      showQRCode: false,
      modalProjectDetail: false,
      curTab: 'workers',
      ejected: false,
      alarmCount: 0,
      complaintCount: 0,
      workerCount: 0,
      personCount: 0,
      reportCount: 0,
      deviceCount: 0,
      teamCount: 0,
      onSiteCount: 0,
      siteMonitorOnline: false,
      modalSiteMonitor: false,
      showMapPicker: false,
      mapPickerMode: 'view',
      mapPickerPickedPoints: []
    }
  },
  mounted () {
    this.$store.dispatch('setPageStore', {
      pageHeader: '操作面板',
      pageDescr: null
    })
    this.getProject().then(() => {
      this.$store.dispatch('setPageStore', {
        pageHeader: '操作面板',
        pageDescr: this.project.Name
      })
      // this.getProjectCrawler(this.project.RegistrationNumber)
      // this.checkSiteMonitorStatus()
      this.getProjectStat()
      let path = this.$route.path.indexOf('/') === 0 ? this.$route.path.substr(1) : this.$route.path
      let params = path.split('/')
      this.curTab = params.length === 4 ? params[params.length - 1] : 'workers'
      // this.$router.push({ path: '/projects/view/' + this.projectId + '/' + this.curTab })
    })
  },
  methods: {
    returnPath () {
      this.$router.back()
    },
    checkRolePermitted (roles) {
      if (!this.$store.getters.permission || !this.$store.getters.permissions.length) {
        return false
      }
      if (this.$store.getters.permission % 2 === 0) {
        // 后台-管理员
        return true
      }
      let userRoles = []
      this.$store.getters.permissions.forEach((p) => {
        userRoles.push(this.$common.permissions[p.toString()])
      })
      if (roles instanceof Array) {
        let inside = false
        roles.forEach((r) => {
          if (userRoles.indexOf(r) > -1) {
            inside = true
          }
        })
        return inside
      }
      return userRoles.indexOf(roles) > -1
    },
    getProject () {
      return new Promise((resolve, reject) => {
        this.$Spin.show()
        this.$Api.Project.getProject(this.$route.params.tid).then((respBody) => {
          this.project = respBody.Project
          QRCode.toDataURL(
            `https://www.zhuzhu777.com/miniprogram-project?_id=${this.$route.params.tid}&_n=${encodeURIComponent(this.project.Name)}`,
            {
              errorCorrectionLevel: 'H',
              type: 'image/jpeg',
              quality: 0.3,
              margin: 1,
              width: 400,
              color: {
                dark:"#268b97FF",
                light:"#FFFFFFFF"
              }
            },
            (err, url) => {
              if (err) throw err
              this.miniprogramQRCode = url
            }
          )
          this.$Spin.hide()
          resolve()
        })
        .catch(err => {
          this.$Spin.hide()
          this.$Notice.error({
            title: '获取项目信息失败!',
            desc: err.message
          })
          reject(err)
        })
      })
    },
    getProjectStat () {
      this.$Api.Project.getProjectStat(this.$route.params.tid).then((respBody) => {
        this.deviceCount = respBody.DeviceCount
        this.personCount = respBody.StaffCount
        this.workerCount = respBody.MigrantWorkerCount
        this.teamCount = respBody.TeamCount
        this.onSiteCount = respBody.OnSiteCount
        this.complaintCount = respBody.ComplaintCount
        this.reportCount = respBody.ReportCount
        this.alarmCount = respBody.AlarmCount
      })
      .catch(err => {
        this.$Notice.error({
          title: '获取项目统计信息失败!',
          desc: err.message
        })
      })
    },
    getProjectCrawler (regNo) {
      if (!this.projectCrawler.projectName) {
        if (!regNo) {
          regNo = this.project.RegistrationNumber
        }
        this.apiItem = {
          apiHost: '//data-sup.zhuzhu777.com/',
          apiService: 'project',
          apiAction: 'basics/',
          apiQuery: {
          }
        }
        this.apiData = {
        }
        this.$store.dispatch('setAPIStore', this.apiItem)
        var apiUrl = this.$store.getters.apiUrl + regNo
        this.$ajax({
          method: 'get',
          url: apiUrl,
          withCredentials: false
        }).then(
          response => {
            this.projectCrawler = response.data
          }).catch(
          () => {
            this.$Message.warning('操作失败！可能是网络原因')
          }
        )
      }
    },
    checkSiteMonitorStatus () {
      this.apiItem = {
        apiHost: '//data-sup.zhuzhu777.com/',
        apiService: 'sitemonitor',
        apiAction: 'status/',
        apiQuery: {
        }
      }
      this.apiData = {
      }
      this.$store.dispatch('setAPIStore', this.apiItem)
      var apiUrl = this.$store.getters.apiUrl + this.projectId
      this.$ajax({
        method: 'get',
        url: apiUrl,
        withCredentials: false
      }).then(
        response => {
          if (response.data.result === 'ok' && response.data.data) {
            this.siteMonitorOnline = true
          }
        }).catch(
        () => {
        }
      )
    },
    siteMonitorClose () {
      this.modalSiteMonitor = false
    },
    openSiteMonitor () {
      // window.open('//localhost:8085/#/' + this.projectId)
      this.modalSiteMonitor = true
    },
    getArea (row) {
      if (row.Province) {
        let county = this.getCityName(leftPad(row.Province, 2) + leftPad(row.Municipality, 2) + leftPad(row.County, 2))
        let municipality = this.getCityName(leftPad(row.Province, 2) + leftPad(row.Municipality, 2) + '00')
        let province = this.getCityName(leftPad(row.Province, 2) + '0000')
        return province + '/' + municipality + '/' + county
      } else {
        return '-'
      }
    },
    getCityName (code) {
      try {
        return cities.filter((item) => {
          return item.code === code
        })[0].city
      } catch (e) {
        return 'unkown'
      }
    },
    toggleEject () {
      this.ejected = !this.ejected
    },
    setComplaintCount (ct) {
      this.complaintCount = ct
    },
    setDeviceCount (ct) {
      this.deviceCount = ct
    },
    setPersonCount (ct) {
      this.personCount = ct
    },
    setReportCount (ct) {
      this.reportCount = ct
    },
    setWorkerCount (ct) {
      this.workerCount = ct
    },
    setAlarmCount (ct) {
      this.alarmCount = ct
    },
    mapMultiPicked (points) {
      console.log(points)
      this.doSubmitGeofence(points)
      this.closeMapPicker()
    },
    mapPicked (points, address) {
      console.log(points, address)
      this.closeMapPicker()
    },
    closeMapPicker () {
      this.showMapPicker = false
      this.mapPickerMode = 'view'
      this.mapPickerPickedPoints = []
    },
    viewProjectInMap () {
      this.mapPickerMode = 'view'
      this.mapPickerPickedPoints = []
      if (this.project.Longitude && this.project.Latitude) {
        this.mapPickerPickedPoints.push([this.project.Longitude / 1000000, this.project.Latitude / 1000000])
      }
      if (this.project.Geofence) {
        let geoPoints = []
        try {
          geoPoints = JSON.parse(this.project.Geofence)
        } catch (err) {
          console.log('地理围栏数据不合法' + err.message)
        }
        geoPoints.forEach((g) => {
          if (g instanceof Array && g.length === 2) {
            this.mapPickerPickedPoints.push([g[1], g[0]])
          }
        })
      }
      this.showMapPicker = true
    },
    setProjectGeofenceMap () {
      this.mapPickerMode = 'multi'
      this.mapPickerPickedPoints = []
      if (this.project.Geofence) {
        let geoPoints = []
        try {
          geoPoints = JSON.parse(this.project.Geofence)
        } catch (err) {
          console.log('地理围栏数据不合法' + err.message)
        }
        geoPoints.forEach((g) => {
          if (g instanceof Array && g.length === 2) {
            this.mapPickerPickedPoints.push([g[1], g[0]])
          }
        })
      }
      this.showMapPicker = true
    },
    doSubmitGeofence (points) {
      this.$Spin.show()
      let pointsNew = []
      points.forEach(p => {
        pointsNew.push([p[1], p[0]])
      })
      let model = {
        Tid: this.project.Tid,
        Geofence: JSON.stringify(pointsNew)
      }
      this.$Api.Project.updateProjectGeofence(model).then(() => {
        this.$Message.success('操作成功！')
        this.$Spin.hide()
        this.getProject()
      })
      .catch(err => {
        this.$Spin.hide()
        this.$Notice.error({
          title: '提交失败！',
          desc: err.message
        })
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-header {
  margin: 10px 0 5px 20px;
}
.profile-project-img {
  margin: 0 auto;
  width: 100px;
  padding: 3px;
  border-radius: 16px;
  border: 3px solid #d2d6de;
}
.profile-project-name {
  color: rgb(15,191,162);
  font-size: 20px;
  font-weight: 500;
}
.brief-card div {
  transition: all .3s ease-in-out;
}
.brief-card .ejected {
  height: 0;
  overflow: hidden;
}
.prj-hover-icons {
  position: absolute;
  top: -16px;
  right: 3px;
  font-size: 16px;
  opacity: 0;
  transition: all .3s ease-in-out;
}
.prj-hover-icons span {
  cursor: pointer;
  padding: 8px;
  opacity: .6;
  transition: all .3s ease-in-out;
}
.prj-hover-icons span:hover {
  opacity: 1;
}
.brief-card:hover .prj-hover-icons {
  opacity: 1;
}
.prj-hover-icons .eject-prj-icon i {
  transition: all .3s ease-in-out;
}
.prj-hover-icons .eject-prj-icon.ejected i {
  transform: rotate(180deg);
}
.project-crawler li.list-group-item b {
  display: inline-block;
  font-weight: 800;
  width: 140px;
}
</style>
