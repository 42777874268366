<template>
  <Modal
    v-model="visible"
    :title="attendanceYear+'年'+attendanceMonth+'月人员签到记录汇总'"
    width="1080"
    :styles="{top: '20px'}"
    :mask-closable="false"
    @on-cancel="returnPath">
    <div class="row project-attendance-detail">
      <div class="col-xs-12" style="clear: both">
        <Table border class="attendance-table" size="small" :columns="columns" :data="dataViewPage" ref="table" @on-selection-change="selectRowChange"></Table>
        <Modal v-model="modalDetail" title="人员详情" v-if="workerSelected">
          <div class="pull-right" v-if="workerSelected.User.HeadImgUrl">
            <img :src="workerSelected.User.HeadImgUrl">
          </div>
          <ul class="list-group list-group-unbordered">
            <li class="list-group-item">
              <b>姓名</b> <span class="">{{ workerSelected.User.Name }}</span>
            </li>
            <li class="list-group-item">
              <b>所属公司</b> <span class="">{{ workerSelected.Unit }}</span>
            </li>
            <li class="list-group-item">
              <b>队伍</b> <span class="">{{ workerSelected.Department }}</span>
            </li>
            <li class="list-group-item">
              <b>工种</b> <span class="">{{ workerSelected.Job }}</span>
            </li>
            <li class="list-group-item">
              <b>进场状态</b> <span><Tag :color="getGenderColor(workerSelected.IsEnable)">{{ getRegEnableText() }}</Tag></span>
            </li>
            <li class="list-group-item">
              <b>性别</b> <span><Tag :color="getGenderColor(workerSelected.User.IsMale)">{{ getGender(workerSelected.User.IsMale) }}</Tag></span>
            </li>
            <li class="list-group-item">
              <b>生日</b> <span class="">{{ getDate(workerSelected.User.Birthday) }}</span>
            </li>
            <li class="list-group-item">
              <b>身份证号</b> <span class="">{{ showIdNumber(workerSelected.User.IdNumber) }}</span>
            </li>
            <li class="list-group-item">
              <b>手机号码</b> <span class="">{{ workerSelected.User.MobilePhoneNumber }}</span>
            </li>
            <li class="list-group-item">
              <b>工资账号</b> <span class="">{{ workerSelected.BankName }} {{ showBankAccountNumber(workerSelected.BankAccountNumber) }}</span>
            </li>
          </ul>
        </Modal>
      </div>
      <workerSignForm :dataSelected="workerSelected" :show="showSignForm" @close="closeSignForm"></workerSignForm>
      <!--<workerAddForm :data="userSelected" :show="showAddForm" @close="closeNewForm"></workerAddForm>-->
    </div>
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">取消</Button>
      <Button type="primary" size="large" @click="exportData"><Icon type="ios-download-outline"></Icon> 导出原始数据</Button>
      <Button type="primary" size="large" @click="refreshList">刷新</Button>
      <div style="clear: both;"></div>
    </div>
  </Modal>
</template>

<script>
// import workerAddForm from './ProjectWorkerAdd'
import workerSignForm from './ProjectWorkerSigninRecords'

export default {
  name: 'project-attendance-detail',
  props: ['dataSelected', 'show'],
  components: {
    // workerAddForm,
    workerSignForm
  },
  data () {
    return {
      project: {},
      projectId: this.$route.params.tid,
      showSignForm: false,
      loading: false,
      userSelected: null,
      workerSelected: null,
      modal_loading: false,
      modalDetail: false,
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      keyword: '',
      searchBy: '',
      searchMode: false,
      tableSearchOn: false,
      columns: [
      ],
      dataRaw: [],
      data: [],
      dataViewPage: [],
      visible: false
    }
  },
  watch: {
    'show': function (val) {
      this.visible = val
      if (val) {
        if (this.dataSelected) {
          this.prepCalendar()
          // this.calendarStartDate = getCurrentMonthFirst()
          // this.calendarEndDate = getCurrentMonthLast()
          this.initDataTable()
        }
      }
    },
    'data': function () {
      this.dataViewPage = this.data
    }
  },
  computed: {
    attendanceYear: function () {
      return (this.dataSelected && this.dataSelected.AttendanceYear) ? this.dataSelected.AttendanceYear : new Date().getFullYear()
    },
    attendanceMonth: function () {
      return (this.dataSelected && this.dataSelected.AttendanceMonth) ? this.dataSelected.AttendanceMonth : new Date().getMonth() + 1
    }
  },
  mounted () {
    if (this.dataSelected && this.dataSelected.AttendanceYear && this.dataSelected.AttendanceMonth) {
      this.initDataTable()
    }
  },
  methods: {
    toggleSignForm () {
      this.showSignForm = true
      // this.$router.replace({ path: '/project/' + this.projectId + '/workers/' + this.workerSelected.UserId + '/records' })
    },
    closeSignForm () {
      this.showSignForm = false
      // this.workerSelected = null
    },
    refreshList () {
      // refreshList()
      this.initDataTable()
      this.searchBy = ''
      this.keyword = ''
      this.searchMode = false
      this.tableSearchOn = false
    },
    returnPath () {
      this.datatableData = []
      this.visible = false
      this.$emit('close')
      this.$Modal.remove()
    },
    prepCalendar () {
      this.columns = [
        {
          title: '姓名',
          key: 'Name',
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.Name.localeCompare(b.Name, 'zh-CN')
            } else {
              return b.Name.localeCompare(a.Name, 'zh-CN')
            }
          }
        },
        {
          title: '总天数',
          key: 'TotalSignedDays',
          ellipsis: true,
          sortable: true
        },
        {
          title: '总次数',
          key: 'TotalSignedTimes',
          ellipsis: true,
          sortable: true
        },
        {
          title: '总工时',
          key: 'TotalWorkingHours',
          ellipsis: true,
          sortable: true,
          render: (h, params) => {
            return h('span', {}, params.row.TotalWorkingHours.toFixed(1))
          }
        }
      ]
      if (this.dataSelected) {
        let _year = this.dataSelected.AttendanceYear
        let _month = this.dataSelected.AttendanceMonth
        // let _date = '01'
        // let _fulldate = new Date(_year, _month, _date)
        let leapyear = 0
        if (_year % 400 === 0 || (_year % 4 === 0 && _year % 100 !== 0)) {
          leapyear = 1
        }
        let days = 28
        if ([1, 3, 5, 7, 8, 10, 12].indexOf(_month) >= 0) {
          days = 31
        } else if ([4, 6, 9, 11].indexOf(_month) >= 0) {
          days = 30
        } else {
          days = days + leapyear
        }
        for (var i = 1; i <= days; i++) {
          this.columns.push({
            title: i,
            key: 'SigninData',
            width: 24,
            render: (h, params) => {
              let daySigns = params.row.SigninData.filter((item) => {
                return item.Day === params.column.title
              })
              if (daySigns.length > 0) {
                return h('span', {
                  class: 'signed'
                }, daySigns[0].WorkingHours.toFixed(1))
              }
            }
          })
        }
      }
    },
    initDataTable () {
      this.$Api.Project.getProjectAttendance(this.$route.params.tid, this.dataSelected.AttendanceYear, this.dataSelected.AttendanceMonth).then((respBody) => {
        console.log(respBody)
        this.getOSSData(respBody, (oss) => {
          this.dataRaw = oss.MigrantWorkers
          this.data = oss.MigrantWorkers
          this.$emit('setWorkerCount', this.dataRaw.length)
        })
      })
      .catch(err => {
        this.$Notice.error({
          title: '列表获取失败！',
          desc: err.message
        })
      })
    },
    getOSSData (url, callback) {
      console.log(url)
      if (url) {
        this.$axios.get(url).then(
          response => {
            console.log(response)
            if (callback) {
              callback(response.data)
            } else {
              return response.data
            }
          }
        )
      }
    },
    selectRowChange (selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    exportData () {
      let cols = [
        { key: '姓名' },
        { key: '总天数' },
        { key: '总次数' },
        { key: '总工时' }
      ]
      let _year = this.dataSelected.AttendanceYear
      let _month = this.dataSelected.AttendanceMonth
      // let _date = '01'
      // let _fulldate = new Date(_year, _month, _date)
      let leapyear = 0
      if (_year % 400 === 0 || (_year % 4 === 0 && _year % 100 !== 0)) {
        leapyear = 1
      }
      let days = 28
      if ([1, 3, 5, 7, 8, 10, 12].indexOf(_month) >= 0) {
        days = 31
      } else if ([4, 6, 9, 11].indexOf(_month) >= 0) {
        days = 30
      } else {
        days = days + leapyear
      }
      for (var i = 1; i <= days; i++) {
        cols.push({
          key: i
        })
      }
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: cols,
        data: this.dataViewPage.map((item) => {
          let dataMap = {
            '姓名': item.Name,
            '总天数': item.TotalSignedDays,
            '总次数': item.TotalSignedTimes,
            '总工时': item.TotalWorkingHours.toFixed(1)
          }
          for (var i = 1; i <= days; i++) {
            let temp = item.SigninData.filter((sign) => {
              return sign.Day === i
            })
            dataMap[i] = temp.length ? temp[0].WorkingHours.toFixed(1) : 0
          }
          return dataMap
        })
      })
    }
  }
}
</script>

<style scoped>
.box-tools {
  display: flex;
}
.table-search {
  transition: width 0.3s ease-in-out;
  width: 0;
  overflow: hidden;
}
.table-search.on {
  width: 120px;
}
</style>

<style>
.attendance-table {
  border: 1px solid #e2e2e2 !important;
  overflow: hidden !important;
}
.attendance-table th {
  text-align: center !important;
  background: #f8f8f8 !important;
  border-bottom: 2px solid #e9eaec !important;
}
.attendance-table .ivu-table-cell {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
.attendance-table .ivu-table-body {
  overflow: hidden !important;
}
.attendance-table .ivu-table-cell span.signed {
  background: #03aa8e !important;
  color: #fff;
  display: block;
  width: 100%;
  line-height: 40px;
}
</style>
