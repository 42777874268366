<template>
  <div class="row project-complaints">
    <div class="box-tools">
      <div style="display: inline-block;margin-right:15px;" @mouseenter="toggleSearch(true)" @mouseleave="toggleSearch(false)">
        <Select v-model="searchBy" class="table-search" :class="{on: tableSearchOn||searchMode||searchBy}">
          <Option value="">不限</Option>
          <Option :value="col.searchKey?col.searchKey:col.key" v-for="col in columns.filter((c)=>{return c.title && c.searchable})" :key="col.key">{{col.title}}</Option>
        </Select>&nbsp;
        <Input v-model="keyword" ref="tableSearch" placeholder="搜索关键字" @on-focus="toggleSearchMode" @on-blur="toggleSearchMode" class="table-search" :class="{on: tableSearchOn||searchMode||keyword}"></Input>&nbsp;
        <Button type="default" icon="ios-search" @click="search"></Button>
      </div>
      <Button-group>
        <Button type="default" icon="md-refresh" @click="refreshList">刷新</Button>
      </Button-group>
    </div>
    <div class="col-xs-12">
      <Table stripe :columns="columns" :data="dataViewPage" ref="table" @on-selection-change="selectRowChange"></Table>
      <div style="margin: 10px;overflow: hidden">
        <Button type="primary" size="large" @click="exportData" :disabled="!rowSelected"><Icon type="ios-download-outline"></Icon> 导出原始数据</Button>
        <div style="float: right;">
          <Page :total="data.length" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="pageCurrent" show-sizer></Page>
        </div>
      </div>
    </div>
    <complaintDetail :show="modalDetail" @close="closeComplaintDetail" :dataSelected="dataSelected"></complaintDetail>
  </div>
</template>

<script>
import complaintDetail from './ProjectComplaintDetail'
export default {
  name: 'project-conplaints',
  props: ['project'],
  components: {
    complaintDetail
  },
  // props: ['data'],
  data () {
    return {
      loading: false,
      dataSelected: null,
      personSelected: null,
      modal_loading: false,
      modalDisable: false,
      modalDetail: false,
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      keyword: '',
      searchBy: '',
      searchMode: false,
      tableSearchOn: false,
      columns: [
        { type: 'selection', width: 40, align: 'center' },
        {
          title: '项目',
          key: 'Project',
          width: 200,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          }
        },
        { title: '内容',
          key: 'Content',
          // width: 120,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          },
          render: (h, params) => {
            let content = this.getContent(params.row)
            if (params.row.Attachments) {
              return h('div', [
                h('span', {
                  class: 'fa fa-paperclip',
                  title: '有附件'
                }),
                h('span', {
                  class: 'font-grey'
                }, content)
              ])
            } else {
              return h('span', {
                class: 'font-grey'
              }, content)
            }
          }
        },
        { title: '状态',
          key: 'IsActive',
          width: 100,
          sortable: true,
          render: (h, params) => {
            if (params.row.IsActive) {
              return h('Tag', {
                props: {
                  // type: 'dot',
                  color: 'green'
                }
              }, '进行中')
            } else {
              return h('Tag', {
                props: {
                  // type: 'dot',
                  color: 'blue'
                }
              }, '已关闭')
            }
          }
        },
        {
          title: '时间',
          key: 'Time',
          width: 160,
          sortable: true,
          render: (h, params) => {
            return h('span', {}, this.$moment(params.row.Time).format('YYYY-MM-DD HH:mm:ss'))
          }
        },
        // { title: '身份证序列号', key: 'IcCardId', sortable: true, searchable: true },
        {
          title: '操作',
          key: 'action',
          // fixed: 'right',
          width: 120,
          render: (h, params) => {
            return h('Button-group', [
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                },
                on: {
                  click: () => {
                    this.dataSelected = null
                    this.dataSelected = params.row
                    this.modalDetail = true
                    // this.$router.push({ path: '/project/' + this.$route.params.id + '/complaints/view/' + params.row.Id })
                  }
                }
              }, '详情')
            ])
          }
        }
      ],
      dataRaw: [],
      data: [],
      dataViewPage: []
    }
  },
  watch: {
    'data': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageCurrent': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageSize': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize
      })
    }
  },
  mounted () {
    this.initDataTable()
  },
  methods: {
    closeComplaintDetail () {
      this.modalDetail = false
    },
    getContent (row) {
      let content = ''
      if (row.Content.length >= 30) {
        content = row.Content.substr(0, 30) + '...'
      } else {
        content = row.Content
      }
      return content
    },
    getDate (time) {
      time = time.replace('T', ' ')
      // return Date.parse(time)
      return time.substr(0, 10)
    },
    refreshList () {
      // refreshList()
      this.initDataTable()
      this.searchBy = ''
      this.keyword = ''
      this.searchMode = false
      this.tableSearchOn = false
    },
    initDataTable () {
      return new Promise((resolve, reject) => {
        this.$Spin.show()
        this.$Api.Project.getProjectComplaints(this.$route.params.tid).then((respBody) => {
          this.dataRaw = respBody.Topics
          this.data = respBody.Topics
          this.pageSize = 10
          this.pageCurrent = 1
          this.$emit('setComplaintCount', this.dataRaw.length)
          this.$Spin.hide()
          resolve()
        })
        .catch(err => {
          this.$Spin.hide()
          this.$Notice.error({
            title: '获取失败!',
            desc: err.message
          })
          reject(err)
        })
      })
    },
    selectRowChange (selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage (page) {
      this.pageCurrent = page
    },
    changePageSize (pageSize) {
      this.pageSize = pageSize
    },
    exportData () {
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: this.columns,
        data: this.rowSelectedList
      })
    },
    toggleSearchMode () {
      this.searchMode = !this.searchMode
    },
    toggleSearch (val) {
      this.tableSearchOn = val
    },
    search () {
      let cols = this.columns.filter((col) => {
        return col.searchable
      })
      this.data = this.dataRaw.filter((item) => {
        if (this.searchBy) {
          let content = this.getSearchQueryContent(item, this.searchBy)
          return content.toString().indexOf(this.keyword) >= 0
        } else {
          let found = false
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i]
            let content = this.getSearchQueryContent(item, col.searchKey ? col.searchKey : col.key)
            if (content && (content.toString().indexOf(this.keyword) >= 0)) {
              found = true
            }
          }
          return found
        }
      })
      this.pageCurrent = 0
      this.pageCurrent = 1
    },
    getSearchQueryContent (item, key) {
      if (!key) {
        return ''
      }
      let qArr = []
      let qArrTemp = key.split('.')
      qArrTemp.forEach((q) => {
        if (q.indexOf(']')) {
          let subArr = q.replace(/]/g, '').split('[')
          subArr.forEach((sub) => {
            qArr.push(sub)
          })
        } else {
          qArr.push(q)
        }
      })
      let content = item
      qArr.forEach((query) => {
        if (content[query]) {
          content = content[query]
        } else {
          content = ''
        }
      })
      return content
    }
  }
}
</script>

<style scoped>
.box-tools {
  display: flex;
  padding: 0 20px;
}
.table-search {
  transition: width 0.3s ease-in-out;
  width: 0;
  overflow: hidden;
}
.table-search.on {
  width: 120px;
}
</style>
