<template>
  <div class="project-person-edit">
    <Modal
      v-model="visible"
      title="修改人员信息"
      :mask-closable="false"
      @on-cancel="returnPath">
      <Form ref="formPersonEdit" :model="userItem" :rules="ruleValidate" :label-width="120">
        <Form-item label="姓名" prop="LegalName">
          <Input v-model="userItem.LegalName" placeholder="请输入姓名"></Input>
        </Form-item>
        <Form-item label="备注" prop="Tag">
          <Input v-model="userItem.Tag" placeholder="人员备注信息，如：“项目经理”、“信息专员”、“人事专员”等"></Input>
        </Form-item>
        <FormItem label="用户权限">
          <Checkbox :value="true" :disabled="true">项目查看</Checkbox>
          <Checkbox v-model="permissions.canEdit">项目修改</Checkbox>
          <Checkbox v-model="permissions.canAssign">关联人员</Checkbox>
          <Checkbox v-model="permissions.canUploadReport">上报月报</Checkbox>
          <Checkbox v-model="permissions.canViewReport">查看月报</Checkbox>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button class="pull-left" size="large" @click="returnPath">取消</Button>
        <Button type="primary" size="large" :loading="submitting" @click="editPerson">提交</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'project-person-edit',
  components: {
  },
  props: ['data', 'show'],
  data () {
    return {
      visible: false,
      permissions: {
      },
      userItem: {
        ProjectId: 0,
        UserId: 0,
        LegalName: '',
        IdNumber: '',
        Tel: '',
        Permission: 1,    // 基础权限
        Tag: ''
      },
      ruleValidate: {
        LegalName: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        Tag: [
          { type: 'string', pattern: '^((?!施工人员).)*$', message: '备注不能含有"施工人员"', trigger: 'blur' }
        ]
      },
      submitEnabled: true,
      submitting: false
    }
  },
  watch: {
    show: function (newVal) {
      this.visible = newVal
    },
    data: function (newVal) {
      if (newVal) {
        this.permissions = {
          canEdit: newVal.CanEdit,
          canAssign: newVal.CanAssign,
          canUploadReport: newVal.CanUploadReport,
          canViewReport: newVal.CanViewReport
        }
        this.userItem = {
          ProjectId: newVal.ProjectId,
          UserId: newVal.UserId,
          LegalName: newVal.User.Name,
          IdNumber: newVal.User.IdNumber,
          Tel: newVal.User.MobilePhoneNumber,
          Permission: newVal.Permission,
          Tag: newVal.Tags.join(' ')
        }
      }
    }
  },
  mounted () {
    if (this.data) {
      this.permissions = {
        canEdit: this.data.CanEdit,
        canAssign: this.data.CanAssign,
        canUploadReport: this.data.CanUploadReport,
        canViewReport: this.data.CanViewReport
      }
      this.userItem = {
        ProjectId: this.data.ProjectId,
        UserId: this.data.UserId,
        LegalName: this.data.User.Name,
        IdNumber: this.data.User.IdNumber,
        Tel: this.data.User.MobilePhoneNumber,
        Permission: this.data.Permission,
        Tag: this.data.Tags.join(' ')
      }
    }
  },
  methods: {
    getPermission (p) {
      return this.userItem.Permission & p
    },
    procPermissions () {
      this.userItem.Permission = this.permissions.canEdit * 2 + this.permissions.canAssign * 8 +
        this.permissions.canUploadReport * 4 + this.permissions.canViewReport * 32
    },
    editPerson () {
      this.procPermissions()
      this.$refs['formPersonEdit'].validate((valid) => {
        if (valid) {
          this.submitting = true
          this.submitEnabled = false
          this.$Spin.show()
          this.modal_loading = true
          this.$Api.Project.editProjectUser(this.userItem).then(() => {
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
            this.$Message.success('操作成功！')
            this.$emit('refresh')
            this.returnPath()
          })
          .catch(err => {
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
            this.$Notice.error({
              title: '操作失败!',
              desc: err.message
            })
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    },
    returnPath () {
      this.$emit('close')
      this.$Modal.remove()
    }
  }
}
</script>

<style>
</style>
