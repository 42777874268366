<template>
  <div>
    <Modal
      v-model="visible"
      title="回访记录"
      width="1024"
      :styles="{ top: '20px' }"
      :mask-closable="false"
      @on-cancel="returnPath"
    >
      <div class="box-body">
        <Table
          stripe
          :columns="columns"
          :data="data"
          ref="table"
        ></Table>
      </div>
      <div slot="footer">
        <!-- <Button class="pull-left" size="large" @click="returnPath">取消</Button> -->
        <Button type="primary" size="large" @click="toggleReviewForm"
          >添加新的回访</Button
        >
        <div style="clear: both"></div>
      </div>
    </Modal>
    <userReviewRecordsAdd
      :UserReview="UserReview"
      :show="showReviewForm"
      @postAdd="hideReviewForm"
    ></userReviewRecordsAdd>
    <userReviewRecordsDetail
      :UserReviewDetail="reviewrecord"
      :show="showReviewDetail"
      @close="closeReviewDetail"
    ></userReviewRecordsDetail>
  </div>
</template>

<script>
import userReviewRecordsAdd from './UserReviewRecordsAdd'
import userReviewRecordsDetail from './UserReviewRecordsDetail'

export default {
  name: "User-ReviewRecords",
  props: ["dataSelected", "show"],
  components: {
    userReviewRecordsAdd,
    userReviewRecordsDetail
  },
  data() {
    return {
      visible: false,
      reviewrecord: {
        reviewid: "",
        UserId: "",
        User: "",
        ProjectId: "",
        Project: "",
        Type: "",
        Score: "",
        Remark: "",
        Time: "",
      },
      UserReview:{
        User: '',
        UserId: '',
        Project: '',
        ProjectId: '',
        reviewid: '',
      },
      showReviewForm: false,
      UserReviewDetail:[],
      showReviewDetail: false,
      project: {},
      datatableData: [],
      columns: [
        { type: "selection", width: 60, align: "center" },
        {
          title: "用户名",
          key: "User",
          width: 120,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === "asc") {
              return a.localeCompare(b, "zh-CN");
            } else {
              return b.localeCompare(a, "zh-CN");
            }
          },
        },
        {
          title: "项目名称",
          key: "Project",
          sortable: true,
          searchable: true,
        },
        {
          title: "回访类型",
          key: "Type",
          sortable: true,
          render: (h, params) => {
            return h('span', {},
            this.changeType(params.row.Type))
          },
        },
        {
          title: "回访评分",
          key: "Score",
          sortable: true,
          searchable: true,
        },
        {
          title: "备注内容",
          key: "Remark",
          sortable: true,
          searchable: true,
          render: (h, params) => {
            return h('div', [
              h('span', {
                class: 'ellipsis'
              }, params.row.Remark)
            ])
          }
        },
        {
          title: " ",
          key: "action",
          fixed: "right",
          width: 60,
          render: (h, params) => {
            return h('Button-group', [
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                },
                on: {
                  click: () => {
                    this.reviewrecord = params.row
                    this.showReviewDetail = true
                  }
                }
              }, '详情'),
            ])
          }
        }
      ],
      data: []
    };
  },
  mounted() {
  },
  watch: {
    show: function (newVal) {
      this.visible = newVal;
    },
    dataSelected: function (newVal) {
      if (newVal.Id === undefined) {
        this.UserId = newVal.User.Id;
        this.ProjectId = newVal.ProjectId;
        this.User = newVal.User.Name
        this.Project = newVal.Project
        this.getReview();
      } else {
        this.UserId = newVal.Id
        this.User = newVal.Name
        this.getReviewid()
      }
    }
  },
  methods: {
    changeType(data) {
      if (data === 0) {
        return data = '无'
      } else if (data === 1) {
        return data = '电话回访'
      } else {
        return data = '未知'
      }
    },
    getReview() {
      this.reviewrecord.UserId = this.UserId;
      this.reviewrecord.ProjectId = this.ProjectId;
      this.reviewrecord.User = this.User
      this.reviewrecord.Project = this.Project
      this.$Api.Project.getReviewwithproject(
        this.reviewrecord.UserId,
        this.reviewrecord.ProjectId,
      )
        .then((respBody) => {
          this.data = respBody.Reviews;
        })
        .catch((err) => {
          this.$Notice.error({
            title: "获取失败！",
            desc: err.message,
          });
          // console.log(err.message);
        });
    },
    getReviewid() {
      this.reviewrecord.UserId = this.UserId;
      this.reviewrecord.ProjectId = this.ProjectId;
      this.reviewrecord.User = this.User
      this.reviewrecord.Project = this.Project
      this.$Api.Project.getReviewUserId(
        this.reviewrecord.UserId,
      )
        .then((respBody) => {
          this.data = respBody.Reviews;
        })
        .catch((err) => {
          this.$Notice.error({
            title: "获取失败！",
            desc: err.message,
          });
          // console.log(err.message);
        });
    },
    toggleReviewForm() {
      this.UserReview = this.reviewrecord
      this.showReviewForm = true;
    },
    hideReviewForm() {
      this.showReviewForm = false;
    },
    toggleReviewDetail() {
      this.showReviewDetail = true
    },
    closeReviewDetail() {
      this.showReviewDetail = false
    },
    returnPath() {
      this.visible = false;
      this.reviewrecord = [];
      this.$emit('close', null)
      this.$Modal.remove();
    },
  },
};
</script>
<style>
.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
