<template>
  <div class="row project-persons">
    <div class="box-tools">
      <div style="display: inline-block;margin-right:15px;" @mouseenter="toggleSearch(true)" @mouseleave="toggleSearch(false)">
        <Select v-model="searchBy" class="table-search" :class="{on: tableSearchOn||searchMode||searchBy}">
          <Option value="">不限</Option>
          <Option :value="col.searchKey?col.searchKey:col.key" v-for="col in columns.filter((c)=>{return c.title && c.searchable})" :key="col.key">{{col.title}}</Option>
        </Select>&nbsp;
        <Input v-model="keyword" ref="tableSearch" placeholder="搜索关键字" @on-focus="toggleSearchMode" @on-blur="toggleSearchMode" class="table-search" :class="{on: tableSearchOn||searchMode||keyword}"></Input>&nbsp;
        <Button type="default" icon="ios-search" @click="search"></Button>
      </div>
      <Button-group>
        <Button type="default" icon="md-add" @click="toggleAddForm">新增</Button>
        <Button type="default" icon="md-refresh" @click="refreshList">刷新</Button>
      </Button-group>
    </div>
    <div class="col-xs-12">
      <Table stripe :columns="columns" :data="dataViewPage" ref="table" @on-selection-change="selectRowChange"></Table>
      <div style="margin: 10px;overflow: hidden">
        <Button type="primary" size="large" @click="exportData" :disabled="!rowSelected"><Icon type="ios-download-outline"></Icon> 导出原始数据</Button>
        <div style="float: right;">
          <Page :total="data.length" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="pageCurrent" show-sizer></Page>
        </div>
      </div>
      <Modal v-model="modalDetail" v-if="personSelected">
        <ul class="list-group list-group-unbordered">
          <li class="list-group-item">
            <b>姓名</b> <span class="">{{ personSelected.User.Name }}</span>
          </li>
          <li class="list-group-item">
            <b>所属公司</b> <span class="">{{ personSelected.Unit }}</span>
          </li>
          <li class="list-group-item">
            <b>部门</b> <span class="">{{ personSelected.Department }}</span>
          </li>
          <li class="list-group-item">
            <b>职务</b> <span class="">{{ personSelected.Job }}</span>
          </li>
          <li class="list-group-item">
            <b>进场状态</b> <span><Tag :color="$common.genderColor[personSelected.IsEnable?'1':'0']">{{ getRegEnableText() }}</Tag></span>
          </li>
          <li class="list-group-item">
            <b>性别</b> <span><Tag :color="$common.genderColor[personSelected.User.IsMale?'1':'0']">{{ $common.gender[personSelected.User.IsMale?'1':'0'] }}</Tag></span>
          </li>
          <li class="list-group-item">
            <b>生日</b> <span class="">{{ $moment(personSelected.User.Birthday).format('YYYY-MM-DD') }}</span>
          </li>
          <li class="list-group-item">
            <b>用户登录名</b> <span class="">{{ personSelected.User.Username }}</span>
          </li>
          <li class="list-group-item">
            <b>用户权限</b> <span class=""><Tag v-for="(perm,permIdx) in getPermissions()" :key="permIdx">{{perm}}</Tag></span>
          </li>
          <li class="list-group-item">
            <b>账号状态</b> <span><Tag :color="$common.genderColor[personSelected.User.IsEnable?'1':'0']">{{ getEnableText() }}</Tag></span>
          </li>
          <li class="list-group-item">
            <b>身份证号</b> <span class="">{{ showIdNumber(personSelected.User.IdNumber) }}</span>
          </li>
          <li class="list-group-item">
            <b>手机号码</b> <span class="">{{ personSelected.User.MobilePhoneNumber }}</span>
          </li>
          <li class="list-group-item">
            <b>IC卡编码</b> <span class="">{{ personSelected.User.IcCardId }}</span>
          </li>
          <li class="list-group-item">
            <b>微信openId</b> <span class="">{{ personSelected.User.Wechat }}</span>
          </li>
          <li class="list-group-item">
            <b>邮箱</b> <span class="">{{ personSelected.User.Email }}</span>
          </li>
          <li class="list-group-item">
            <b>注册日期</b> <span class="">{{ $moment(personSelected.User.CreationTime).format('YYYY-MM-DD') }}</span>
          </li>
        </ul>
      </Modal>
    </div>
    <projectPersonAddView :data="projectId" :show="showAddForm" @postAdd="hideAddForm"></projectPersonAddView>
    <projectPersonEditView :show="showEditForm" @close="showEditForm=false" :data="personSelected" />
    <projectPersonRemoveModal :show="showRemoveModal" @close="showRemoveModal=false" :data="personSelected" :projectId="$route.params.tid" />
  </div>
</template>

<script>
import projectPersonAddView from './ProjectPersonAdd'
import projectPersonEditView from './ProjectPersonEdit'
import projectPersonRemoveModal from './ProjectPersonRemove'

export default {
  name: 'project-persons',
  props: ['project'],
  components: {
    projectPersonAddView,
    projectPersonEditView,
    projectPersonRemoveModal
  },
  data () {
    return {
      projectId: this.$route.params.tid,
      apiItem: {
        apiHost: '',
        apiService: 'ZztPrjBiz',
        apiAction: 'Users/',
        apiQuery: {
        }
      },
      apiData: {
      },
      showAddForm: false,
      showEditForm: false,
      showRemoveModal: false,
      loading: false,
      userSelected: null,
      personSelected: null,
      modal_loading: false,
      modalDisable: false,
      modalDetail: false,
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      keyword: '',
      searchBy: '',
      searchMode: false,
      tableSearchOn: false,
      columns: [
        { type: 'selection', width: 40, align: 'center' },
        {
          title: '姓名',
          searchKey: 'User.Name',
          width: 120,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.Name.localeCompare(b.Name, 'zh-CN')
            } else {
              return b.Name.localeCompare(a.Name, 'zh-CN')
            }
          },
          render: (h, params) => {
            return h('span', {}, params.row.User.Name)
          }
        },
        {
          title: '部门',
          key: 'Department',
          width: 120,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          }
        },
        {
          title: '职务',
          key: 'Job',
          width: 120,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          }
        },
        { title: '备注',
          key: 'Tags',
          // width: 120,
          sortable: true,
          searchable: true,
          render: (h, params) => {
            return h('span', {}, this.getTags(params.row.Tags))
          }
        },
        { title: '身份证号',
          searchKey: 'User.IdNumber',
          width: 150,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.IdNumber.localeCompare(b.IdNumber, 'zh-CN')
            } else {
              return b.IdNumber.localeCompare(a.IdNumber, 'zh-CN')
            }
          },
          render: (h, params) => {
            return h('span', {}, this.showIdNumber(params.row.User.IdNumber))
          }
        },
        {
          title: '手机号',
          searchKey: 'User.MobilePhoneNumber',
          width: 150,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.MobilePhoneNumber.localeCompare(b.MobilePhoneNumber, 'zh-CN')
            } else {
              return b.MobilePhoneNumber.localeCompare(a.MobilePhoneNumber, 'zh-CN')
            }
          },
          render: (h, params) => {
            return h('span', {}, params.row.User.MobilePhoneNumber)
          }
        },
        // { title: '身份证序列号', key: 'IcCardId', sortable: true, searchable: true },
        {
          title: '操作',
          key: 'action',
          // fixed: 'right',
          width: 160,
          render: (h, params) => {
            return h('Button-group', [
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small',
                  dataToggle: 'modal',
                  dataTarget: '#userListDetail'
                },
                style: {
                },
                on: {
                  click: () => {
                    this.personSelected = null
                    this.personSelected = params.row
                    this.modalDetail = true
                    // this.$router.push({ path: '/project/' + this.projectId + '/persons/' + params.row.UserId })
                  }
                }
              }, '详情'),
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small',
                  dataToggle: 'modal',
                  dataTarget: '#prjPersonEdit'
                },
                style: {
                },
                on: {
                  click: () => {
                    this.personSelected = null
                    this.personSelected = params.row
                    this.showEditForm = true
                  }
                }
              }, '权限'),
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small',
                  dataToggle: 'modal',
                  dataTarget: '#prjPersonEdit'
                },
                on: {
                  click: () => {
                    this.userSelected = null
                    this.userSelected = params.row.User
                    this.showRemoveModal = true
                  }
                }
              }, '解除')
            ])
          }
        }
      ],
      dataRaw: [],
      data: [],
      dataViewPage: []
    }
  },
  watch: {
    'data': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageCurrent': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageSize': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize
      })
    }
  },
  mounted () {
    this.initDataTable()
  },
  methods: {
    toggleAddForm () {
      this.showAddForm = true
    },
    getTags (tagStr) {
      return tagStr.toString()
    },
    getPermissions () {
      let perms = []
      if (this.personSelected.User.Permissions) {
        this.personSelected.User.Permissions.forEach((p) => {
          perms.push(this.$common.permissions[p.toString()])
        })
      }
      return perms
    },
    getEnableText () {
      var text = this.personSelected.User.IsEnable ? '已启用' : '已禁用'
      return text
    },
    getRegEnableText () {
      var text = this.personSelected.IsEnable ? '已进场' : '已退场'
      return text
    },
    showIdNumber (id) {
      let idnumber = ''
      if (id) {
        if (id.length === 18) {
          // idnumber = id.substr(0, 6) + '****' + id.substr(14, 18)
          idnumber = id.substr(0, 10) + '****'
        } else if (id.length === 15) {
          idnumber = id.substr(0, 8) + '****'
        }
      }
      return idnumber
    },
    refreshList () {
      // refreshList()
      this.initDataTable()
      this.searchBy = ''
      this.keyword = ''
      this.searchMode = false
      this.tableSearchOn = false
    },
    initDataTable () {
      return new Promise((resolve, reject) => {
        this.$Spin.show()
        this.$Api.Project.getProjectWorkers(this.$route.params.tid).then((respBody) => {
          this.dataRaw = respBody
          this.data = respBody
          this.pageSize = 10
          this.pageCurrent = 1
          this.$emit('setPersonCount', this.dataRaw.length)
          this.$Spin.hide()
          resolve()
        })
        .catch(err => {
          this.$Spin.hide()
          this.$Notice.error({
            title: '获取项目施工人员名单失败!',
            desc: err.message
          })
          reject(err)
        })
      })
    },
    selectRowChange (selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage (page) {
      this.pageCurrent = page
    },
    changePageSize (pageSize) {
      this.pageSize = pageSize
    },
    exportData () {
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: [
          { key: '姓名' },
          { key: '公司' },
          { key: '部门' },
          { key: '职务' },
          { key: '身份证号' },
          { key: '手机号' },
          { key: '备注' }
        ],
        data: this.rowSelectedList.map((item) => {
          return {
            '姓名': item.User.Name,
            '劳务公司': item.Unit,
            '队伍': item.Department,
            '工种': item.Job,
            '身份证号': '="' + item.User.IdNumber + '"',
            '手机号': '="' + item.User.MobilePhoneNumber + '"',
            '备注': item.Tags
          }
        })
      })
    },
    toggleSearchMode () {
      this.searchMode = !this.searchMode
    },
    toggleSearch (val) {
      this.tableSearchOn = val
    },
    search () {
      let cols = this.columns.filter((col) => {
        return col.searchable
      })
      this.data = this.dataRaw.filter((item) => {
        if (this.searchBy) {
          let content = this.getSearchQueryContent(item, this.searchBy)
          return content.toString().indexOf(this.keyword) >= 0
        } else {
          let found = false
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i]
            let content = this.getSearchQueryContent(item, col.searchKey ? col.searchKey : col.key)
            if (content && (content.toString().indexOf(this.keyword) >= 0)) {
              found = true
            }
          }
          return found
        }
      })
      this.pageCurrent = 0
      this.pageCurrent = 1
    },
    getSearchQueryContent (item, key) {
      if (!key) {
        return ''
      }
      let qArr = []
      let qArrTemp = key.split('.')
      qArrTemp.forEach((q) => {
        if (q.indexOf(']')) {
          let subArr = q.replace(/]/g, '').split('[')
          subArr.forEach((sub) => {
            qArr.push(sub)
          })
        } else {
          qArr.push(q)
        }
      })
      let content = item
      qArr.forEach((query) => {
        if (content[query]) {
          content = content[query]
        } else {
          content = ''
        }
      })
      return content
    },
    getUser (userId) {
      this.userSelected = null
      this.userSelected = this.datatableData.filter(function (item) {
        return item.UserId === userId
      })[0].User
    },
    getPerson (userId) {
      this.userSelected = null
      this.userSelected = this.datatableData.filter(function (item) {
        return item.UserId === userId
      })[0]
    },
    getUserPermissionsStr (row) {
      let resultStr = '项目查看'
      if (row.CanEdit) {
        resultStr += '、项目修改'
      }
      if (row.CanAssign) {
        resultStr += '、关联人员'
      }
      if (row.CanUploadReport) {
        resultStr += '、上报月报'
      }
      if (row.CanViewReport) {
        resultStr += '、查看月报'
      }
      return resultStr
    },
    hideAddForm () {
      this.showAddForm = false
    }
  }
}
</script>

<style scoped>
.box-tools {
  display: flex;
  padding: 0 20px;
}
.table-search {
  transition: width 0.3s ease-in-out;
  width: 0;
  overflow: hidden;
}
.table-search.on {
  width: 120px;
}
</style>
