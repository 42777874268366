<template>
  <div class="row project-attendances">
    <div class="box-tools">
      <div style="display: inline-block;margin-right:15px;" @mouseenter="toggleSearch(true)" @mouseleave="toggleSearch(false)">
        <Select v-model="searchBy" class="table-search" :class="{on: tableSearchOn||searchMode||searchBy}">
          <Option value="">不限</Option>
          <Option :value="col.searchKey?col.searchKey:col.key" v-for="col in columns.filter((c)=>{return c.title && c.searchable})" :key="col.key">{{col.title}}</Option>
        </Select>&nbsp;
        <Input v-model="keyword" ref="tableSearch" placeholder="搜索关键字" @on-focus="toggleSearchMode" @on-blur="toggleSearchMode" class="table-search" :class="{on: tableSearchOn||searchMode||keyword}"></Input>&nbsp;
        <Button type="default" icon="ios-search" @click="search"></Button>
      </div>
      <Button-group>
        <!--<Button type="default" icon="md-add" @click="toggleNewForm">新增</Button>-->
        <Button type="default" icon="md-refresh" @click="refreshList">刷新</Button>
      </Button-group>
    </div>
    <div class="col-xs-12">
      <Table stripe :columns="columns" :data="dataViewPage" ref="table" @on-selection-change="selectRowChange"></Table>
      <div style="margin: 10px;overflow: hidden">
        <Button type="primary" size="large" @click="exportData" :disabled="!rowSelected"><Icon type="ios-download-outline"></Icon> 导出原始数据</Button>
        <div style="float: right;">
          <Page :total="data.length" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="pageCurrent" show-sizer></Page>
        </div>
      </div>
    </div>
    <attendance-detail :dataSelected="dataSelected" :show="modalAttendanceDetail" @close="modalAttendanceDetail=false"></attendance-detail>
  </div>
</template>

<script>
import attendanceDetail from './ProjectAttendanceDetail'

export default {
  name: 'project-attendances',
  props: ['project'],
  components: {
    attendanceDetail
  },
  data () {
    return {
      projectId: this.$route.params.id,
      estDate: '2021-10-01',        // 模拟项目创建时间
      loading: false,
      dataSelected: null,
      modalAttendanceDetail: false,
      modal_loading: false,
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      keyword: '',
      searchBy: '',
      searchMode: false,
      tableSearchOn: false,
      columns: [
        { type: 'selection', width: 70, align: 'center' },
        {
          title: '年度',
          key: 'AttendanceYear',
          sortable: true,
          searchable: true
        },
        {
          title: '月度',
          key: 'AttendanceMonth',
          sortable: true,
          searchable: true
        },
        {
          title: '操作',
          key: 'action',
          // fixed: 'right',
          // width: 180,
          render: (h, params) => {
            return h('Button-group', [
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                },
                on: {
                  click: () => {
                    this.dataSelected = params.row
                    this.modalAttendanceDetail = true
                  }
                }
              }, '详情')
            ])
          }
        }
      ],
      dataRaw: [],
      data: [],
      dataViewPage: []
    }
  },
  watch: {
    'data': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageCurrent': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageSize': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize
      })
    }
  },
  mounted () {
    this.initDataTable()
  },
  methods: {
    refreshList () {
      this.initDataTable()
      this.searchBy = ''
      this.keyword = ''
      this.searchMode = false
      this.tableSearchOn = false
    },
    initDataTable () {
      let monthSet = []
      let curYear = new Date().getFullYear()
      let curMonth = new Date().getMonth()
      let estYear = curYear
      let estMonth = curMonth
      if (this.project.CreatedTime) {
        estYear = new Date(this.project.CreatedTime).getFullYear()
        estMonth = new Date(this.project.CreatedTime).getMonth()
      }
      for (var y = estYear; y <= curYear; y++) {
        for (var m = 0; m < 12; m++) {
          if (y === estYear && m < estMonth) {
            continue
          } else if (y === curYear && m > curMonth) {
            break
          } else {
            monthSet.push({
              AttendanceYear: y,
              AttendanceMonth: m + 1
            })
          }
        }
      }
      monthSet.reverse()
      this.dataRaw = monthSet
      this.data = monthSet
      this.pageSize = 10
      this.pageCurrent = 1
    },
    selectRowChange (selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage (page) {
      this.pageCurrent = page
    },
    changePageSize (pageSize) {
      this.pageSize = pageSize
    },
    exportData () {
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: [
          { key: '姓名' },
          { key: '劳务公司' },
          { key: '队伍' },
          { key: '工种' },
          { key: '身份证号' },
          { key: '手机号' },
          { key: '状态' }
        ],
        data: this.rowSelectedList.map((item) => {
          return {
            '姓名': item.User.Name,
            '劳务公司': item.Unit,
            '队伍': item.Department,
            '工种': item.Job,
            '身份证号': '="' + item.User.IdNumber + '"',
            '手机号': '="' + item.User.MobilePhoneNumber + '"',
            '状态': item.IsEnable ? '已进场' : '已退场'
          }
        })
      })
    },
    toggleSearchMode () {
      this.searchMode = !this.searchMode
    },
    toggleSearch (val) {
      this.tableSearchOn = val
    },
    search () {
      let cols = this.columns.filter((col) => {
        return col.searchable
      })
      this.data = this.dataRaw.filter((item) => {
        if (this.searchBy) {
          let content = this.getSearchQueryContent(item, this.searchBy)
          return content.toString().indexOf(this.keyword) >= 0
        } else {
          let found = false
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i]
            let content = this.getSearchQueryContent(item, col.searchKey ? col.searchKey : col.key)
            if (content && (content.toString().indexOf(this.keyword) >= 0)) {
              found = true
            }
          }
          return found
        }
      })
      this.pageCurrent = 0
      this.pageCurrent = 1
    },
    getSearchQueryContent (item, key) {
      if (!key) {
        return ''
      }
      let qArr = []
      let qArrTemp = key.split('.')
      qArrTemp.forEach((q) => {
        if (q.indexOf(']')) {
          let subArr = q.replace(/]/g, '').split('[')
          subArr.forEach((sub) => {
            qArr.push(sub)
          })
        } else {
          qArr.push(q)
        }
      })
      let content = item
      qArr.forEach((query) => {
        if (content[query]) {
          content = content[query]
        } else {
          content = ''
        }
      })
      return content
    }
  }
}
</script>
