<template>
  <Modal
    v-model="visible"
    :mask-closable="false"
    @on-cancel="returnPath"
    title="新增回访记录"
    width="800"
  >
    <Form
      ref="formReviewAdd"
      style="max-width: 600px"
      :model="reviewItem"
      :rules="ruleValidate"
      :label-width="140"
    >
      <Form-item label="姓名" prop="UserId">
        <Input
          type="text"
          v-model="reviewItem.User"
          placeholder="姓名"
          number disabled
        ></Input>
      </Form-item>
      <Form-item label="项目名称" prop="ProjectId">
        <Input
          type="text"
          v-model="reviewItem.Project"
          placeholder="项目名称"
          number disabled
        ></Input>
      </Form-item>
      <Form-item label="回访类型" prop="ReviewType">
        <Select v-model="reviewItem.ReviewType" placeholder="回访类型">
          <Option :value="1">电话回访</Option>
          <!-- <Option value=""></Option> -->
          <!-- <Option value=""></Option> -->
        </Select>
        <!-- <Input type="text" v-model="reviewItem.ReviewType" placeholder="回访类型" number></Input> -->
      </Form-item>
      <Form-item label="评分" prop="Score">
        <Slider v-model="reviewItem.Score" :marks="marks"></Slider>
        <!-- <Input type="text" v-model="reviewItem.Score" placeholder="评分" number></Input> -->
      </Form-item>
      <Form-item label="备注" prop="Remark">
        <Input
          type="textarea"
          :autosize="{ minRows: 8, maxRows: 25 }"
          v-model="reviewItem.Remark"
          placeholder="备注"
        ></Input>
      </Form-item>
    </Form>
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">取消</Button>
      <Button
        type="primary"
        size="large"
        :loading="submitting"
        @click="addReview"
        >提交</Button
      >
    </div>
  </Modal>
</template>

<script>
export default {
  props: ["UserReview", "show"],
  data() {
    return {
      reviewItem: {
        UserId: "",
        ProjectId: "",
        User: "",
        Project: "",
        ReviewType: "",
        Score: 0,
        Remark: "",
        reviewid: "",
      },
      marks: {
        0: {
          style: {
            'padding-top': "10px",
          },
          label: "差",
        },
        25: {
          style: {
            'padding-top': "10px",
          },
          label: "一般",
        },
        50: {
          style: {
            'padding-top': "10px",
          },
          label: "良好",
        },
        75: {
          style: {
            'padding-top': "10px",
          },
          label: "满意",
        },
        100: {
          style: {
            left: "0px",
            right: "-60px",
            'padding-top': "10px",
          },
          label: "非常满意",
        },
      },
      ruleValidate: {
        // UserId:[
        //   { required: true, message: 'Please select the city', trigger: 'change', type:'number' }
        // ],
        ReviewType:[
          { required: true, message: '请选择回访类型！', trigger: 'change', type:'number' }
        ],
        Score:[
          { required: true, message: '请进行评分！', trigger: 'change', type:'number', min:0.5 }
        ],
        Remark:[
          { required: true, message: '请输入备注内容!', trigger: 'blur' },
          { type: 'string', min: 5, message: '备注内容不能少于5个字！', trigger: 'blur' }
        ]
      },
      visible: false,
      submitting: false,
      submitEnabled: true,
      UserId: '',
      ProjectId: '',
    };
  },
  mounted() {
  },
  watch: {
    UserReview: function (newVal) {
      if (newVal.ProjectId === undefined ) {
        this.UserId = newVal.UserId
        this.reviewItem.User = newVal.User
        this.ProjectId = ''
      } else {
        this.UserId = newVal.UserId;
        this.ProjectId = newVal.ProjectId;
        this.reviewItem.User = newVal.User
        this.reviewItem.Project = newVal.Project
      }
    },
    show: function (newVal) {
      this.visible = newVal;
    },
  },
  methods: {
    addReview() {
      this.$refs["formReviewAdd"].validate((valid) => {
        if (valid) {
          this.submitting = true;
          this.submitEnabled = false;
          if (this.ProjectId === '' ) {
            this.reviewItem.UserId = this.UserId
            this.reviewItem.ProjectId = ''
          } else {
            this.reviewItem.UserId = this.UserId
            this.reviewItem.ProjectId = this.ProjectId
            // console.log(this.reviewItem.UserId);
          }
          this.$Api.Project.AddReview(this.reviewItem)
            .then(() => {
              this.$Message.success("操作成功！");
              this.reviewItem = {
                User: this.reviewItem.User,
                Project: this.reviewItem.Project,
                UserId: "",
                ProjectId: "",
                ReviewType: '',
                Score: [''],
                Remark: "",
              };
              this.returnPath();
            })
            .catch((err) => {
              this.submitEnabled = true;
              this.submitting = false;
              this.$Notice.error({
                title: "操作失败!",
                desc: err.message,
              });
              // console.log(err.message);
            });
        } else {
          this.$Message.error("表单验证失败！");
          this.submitEnabled = true;
          this.submitting = false;
        }
      });
    },
    returnPath() {
      this.$emit("postAdd", null);
      this.$Modal.remove();
      this.$refs['formReviewAdd'].resetFields()
      this.submitting = false;
      this.submitEnabled = false;
    },
  },
};
</script>

<style lang="less" scoped>
.list-group.list-group-unbordered {
  padding-top: 20px;
}
.ivu-form-item {
  padding-top: 10px;
  /* text-align: initial; */
}
</style>
