<template>
  <Modal
    v-model="visible"
    title="用户标签更改"
    :mask-closable="false"
    :styles="{ top: '40px', width: '650px' }"
    @on-cancel="returnPath"
    ><div class="center">
      <div class="add">
        已选:
        <Tag
          v-for="(tag, index) in TagsItem"
          :key="index"
          type="border"
          color="primary"
          @click.native="handleClose(tag)"
        >
          <span style="cursor: pointer">
            {{ tag.TagName }}
          </span>
        </Tag>
      </div>
      <div class="all" style="padding-top: 30px">
        可选：
        <Tag
          v-for="(tagall, index) in TagsList"
          :key="index"
          type="border"
          color="primary"
          @click.native="handleAdd(tagall)"
          >
          <span style="cursor: pointer">
            {{ tagall.TagName }}
          </span>
        </Tag>
      </div>
    </div>
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">取消</Button>
      <!-- <Button type="primary" size="large" :loading="submitting" @click="addPerson">提交</Button> -->
    </div>
  </Modal>
</template>

<script>
export default {
  props: ["data", "show", "tagdata"],
  watch: {
    show: function (newVal) {
      this.visible = newVal;
    },
    tagdata: function (newVal) {
      if (newVal) {
        this.userId = newVal.Id;
        this.UserTags = newVal.UserTags;
      }
      this.getTagsData();
    },
  },
  data() {
    return {
      showtag: true,
      permissions: {},
      userId: "",
      TagsItem: {
        TagId: "",
        TagName: "",
      },
      TagsList: {
        TagId: "",
        TagName: "",
      },
      submitEnabled: true,
      submitting: false,
      visible: false,
      filter: {},
    };
  },
  mounted() {
  },
  methods: {
    closetag(tag) {
    let asd
    let TagsItemmm = this.TagsItem.filter(function (item, index) {
      if (item.TagId === tag.TagId) {
        asd = index
        return asd + 1
      } else {
        // console.log('ss')
      }
      })
      if (!TagsItemmm.length) {
        return false
      }
      this.TagsItem.splice(asd, 1)
      this.TagsList.push(tag)
    },
    addtag(tagall) {
      let a
      let filteredElements = this.TagsList.filter(function (item, index) {
        a = index;
          return item.TagId == tagall.TagId;
      })
        if (!filteredElements.length) {
          return false
        }
        this.TagsList.splice(a, 1)
        this.TagsItem.push(tagall)
        // this.getTagsData()
    },
    handleClose(tag) {
      this.$Api.Project.removeUserTags({
        UserId: this.userId,
        TagId: tag.TagId,
      })
        .then(() => {
          this.submitEnabled = true;
          this.$Message.success("操作成功！")
          this.closetag(tag)
        })
        .catch((err) => {
          this.submitEnabled = true
          this.$Notice.error({
            title: "操作失败!",
            desc: err.message,
          })
        })
    },
    handleAdd(tagall) {
      this.$Api.Project.addUserTags({
        UserId: this.userId,
        TagId: tagall.TagId,
      })
        .then(() => {
          this.submitEnabled = true
          this.$Message.success("操作成功！")
          this.addtag(tagall)
          this.getTagsData()
        })
        .catch((err) => {
          this.submitEnabled = true
          this.$Notice.error({
            title: "操作失败!",
            desc: err.message,
          });
        });
    },
    getTagsData() {
      this.TagsItem = this.UserTags
      this.$Api.Project.getAllTags(this.filter)
        .then((respBody) => {
          this.TagsList = respBody.Tags
          let newlist = this.TagsItem.map((item) => item.TagId)
          this.TagsList = this.TagsList.filter((item) => {
            return !newlist.includes(item.TagId)
          })
        })
        .catch((err) => {
          this.$Notice.error({
            title: "获取标签列表失败!",
            desc: err.message,
          })
          // console.log(err.message)
        })
    },
    returnPath() {
      this.$emit("close", null)
      this.$Modal.remove()
    },
  },
};
</script>

<style>
</style>
